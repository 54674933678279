import {Injectable, Injector} from "@angular/core";
import {ConfigListMap, preference, schedulerLink} from "./app-objects.model";
import {LabelMap} from "./default-labels.model";
import {SpanishLabelMap} from "../languages/label-map-es.model";
import {TitleCasePipe} from "@angular/common";
import {DutchLabelMap} from "../languages/label-map-nl.model";
import {ConfigListService} from '../config-list.service';
import {FrenchLabelMap} from "../languages/label-map-fr.model";

@Injectable()
export class LabelMapService {
    configListService: ConfigListService;
    constructor(private titleCasePipe: TitleCasePipe, private injector: Injector){
        this.configListService = injector.get(ConfigListService);
    }
    getTranslatedLabelMapValues(reconciledLabelMap: LabelMap, preference: preference){
        let originalLabelMapValues = new LabelMap(this.configListService.configList,
                                            this.titleCasePipe,
                                            preference.cancelHrs,
                                            preference.rescheduleHrs,
                                            preference.waitListFirstAvailable);

        let languageLabelMap = null;
        let languageSubstring = sessionStorage.getItem('locale');
        if(languageSubstring.indexOf("-")!==-1){
            let indexOfHyphen = languageSubstring.indexOf("-");
            languageSubstring = languageSubstring.substring(0,indexOfHyphen);
        }
        switch (languageSubstring) {
            case 'es':
                languageLabelMap = new SpanishLabelMap(this.configListService.configList,
                    this.titleCasePipe,
                    preference.cancelHrs,
                    preference.rescheduleHrs,
                    preference.waitListFirstAvailable);
                break;
            case 'fr':
                languageLabelMap = new FrenchLabelMap(this.configListService.configList,
                    this.titleCasePipe,
                    preference.cancelHrs,
                    preference.rescheduleHrs,
                    preference.waitListFirstAvailable);
                break;
            case 'nl':
                languageLabelMap = new DutchLabelMap(this.configListService.configList,
                    this.titleCasePipe,
                    preference.cancelHrs,
                    preference.rescheduleHrs,
                    preference.waitListFirstAvailable);
                break;
            default:
                return reconciledLabelMap;
        }
        // let propsToPrint = ['thanksCourses','thanksInfo']
        for(let prop in reconciledLabelMap){

            // if(propsToPrint.indexOf(prop) !== -1){//USed to check if there is a discrepancy in properties taht are nto translating by default
            //     console.log(originalLabelMapValues[prop]);
            //     console.log(reconciledLabelMap[prop]);
            // }
            if(originalLabelMapValues.hasOwnProperty(prop) && originalLabelMapValues[prop] === reconciledLabelMap[prop] &&
                languageLabelMap.hasOwnProperty(prop) && languageLabelMap[prop] !== null){
                reconciledLabelMap[prop] = languageLabelMap[prop];
            }
        }
        return reconciledLabelMap;
    }

    reconcileLabels(preference: preference, preferenceLabelMap: LabelMap, configListLabels: ConfigListMap){
        let labelMap = new LabelMap(this.configListService.configList,
                            this.titleCasePipe,
                            preference.cancelHrs,
                            preference.rescheduleHrs,
                            preference.waitListFirstAvailable);

        //let originalLabelMapValues = new LabelMap(configListLabels, this.titleCasePipe, this.businessService.landingPageObjects.preference);
        for (let key in preference) {
            if (this.hasOwnProperty(key) && preference[key] !== null) {
                labelMap[key] = preference[key];
            }
        }
        let keysToSetIfEmptyString = ['thanksInfoPayment','thanksInfoPaymentRequired','thanksInfoPaymentAuthOnly','thanksCourses','thanksInfo','thanksInfoConfirm'];
        let keysToSetToDotNotation = [{labelMapProp: 'location.locationName', preferenceLabelMapProp: 'locationName'}, {labelMapProp: 'staff.fullName', preferenceLabelMapProp: 'staffFullName'}, {labelMapProp: 'reason.reasonDesc', preferenceLabelMapProp: 'reasonDesc'}];
        for (let key in preferenceLabelMap) {
            if (preferenceLabelMap.hasOwnProperty(key) && preferenceLabelMap[key] !== null &&
                (keysToSetIfEmptyString.indexOf(key) === -1 || (keysToSetIfEmptyString.indexOf(key) !== -1 && preferenceLabelMap[key].trim() !== ""))) {
                labelMap[key] = preferenceLabelMap[key];
            }
        }
        for (let i = 0, x = keysToSetToDotNotation.length; i < x; i++) {
            if (preferenceLabelMap[keysToSetToDotNotation[i].preferenceLabelMapProp])
                labelMap[keysToSetToDotNotation[i].labelMapProp] = preferenceLabelMap[keysToSetToDotNotation[i].preferenceLabelMapProp];

        }
        if(preference.autoTranslateDefaultLabels)
            this.getTranslatedLabelMapValues(labelMap, preference);
        // if(labelMap['timePnlWaitlistText'] === originalLabelMapValues['timePnlWaitlistText'] && preference.waitListFirstAvailable){
        //     labelMap['timePnlWaitlistText'] = "<div style='text-align: center;'>If you only want to be notified if specific date(s) and time(s) become available, then select those form the calendar below. Otherwise, you can click the button to be notified of the first available opening:</div>";
        // }

        return labelMap;
    }
    reconcileSchedulerLinkPropertiesToLabelMap(schedulerLink: schedulerLink, schedulerLinkLabelMap: LabelMap, labelMap: LabelMap){
        // let defaultLabelMap = this.getTranslatedLabelMapValues();
        //FIRST FOR SCHEDULER LINKS, TAKE ANY VALUES THAT ARE FLAT ON TEH SCHEDULER LINK AND MIGRATE TO THE LABELMAP
        for (let key in schedulerLink) {
            if (this.hasOwnProperty(key) && schedulerLink[key] !== null) {
                labelMap[key] = schedulerLink[key];
            }
        }
        if(schedulerLinkLabelMap !== null) {
            for (let key in labelMap) {
                if (!schedulerLinkLabelMap.hasOwnProperty(key)) {
                    schedulerLinkLabelMap[key] = labelMap[key];
                }
            }
        } else {
            schedulerLinkLabelMap = labelMap;
        }
        return schedulerLinkLabelMap;
    }

}
