


import {Injectable, Injector} from "@angular/core";
import {TableColumnDataFilterFormat, TableColumnDataModel} from "./common-classes/TableColumnData.model";
import {DatePipe, TitleCasePipe} from "@angular/common";
import {BusinessService} from "./business.service";
import * as momentTimezone from "moment-timezone/moment-timezone";
import {Client, preference} from "./common-classes/app-objects.model";
import {MomentTimezoneService} from "./scheduler/time/moment-timezones.service";
import {TagsService} from "./tags.service";
import {TimeService} from './scheduler/time/time.service';
import {SchedulerPreferenceService} from './scheduler-preference.service';
import {ConfigListService} from './config-list.service';
import {MilitaryTimeConversion} from '../pipes/militaryTimeConversion.pipe';

@Injectable()
export class DataTablesService {
    schedulerPreferenceService: SchedulerPreferenceService;
    configListService: ConfigListService;
    fullDateFilterUTC = new TableColumnDataFilterFormat('fullDate', false, false, true);
    shortTimeFilterUTC = new TableColumnDataFilterFormat('shortTime', false, false, true);
    shortTimeFilter = new TableColumnDataFilterFormat('shortTime');
    momentTimeFilter = new TableColumnDataFilterFormat('h:mm a z', false, true);
    constructor(private titleCasePipe: TitleCasePipe, private datePipe: DatePipe, private tagsService: TagsService, private timeService: TimeService,
                private businessService: BusinessService, private momentTimezoneService: MomentTimezoneService,
                private injector: Injector, private militaryTime: MilitaryTimeConversion) {
        this.schedulerPreferenceService = injector.get(SchedulerPreferenceService);
        this.configListService = injector.get(ConfigListService);
    }


    dateFileFormats: TableColumnDataFilterFormat[] = [
        new TableColumnDataFilterFormat('EEEE, MMM d, y', false, false),
        new TableColumnDataFilterFormat(' at ', true, false),
        new TableColumnDataFilterFormat('h:mm a z', false, true)
    ];
    simpleDateFileFormat: TableColumnDataFilterFormat[] = [
        new TableColumnDataFilterFormat('EEEE, MMM d, y', false, false)
    ];
    commonTableDataColumns: TableColumnDataModel[] = [
        new TableColumnDataModel('status', 'Status','chip'),
    ];

    getProperty = (obj, path) => (
        path.split('.').reduce((o, p) => o && o[p], obj)
    );

    getFilterDisplay = function(element, col, selectedClient?: Client){
        let timeZoneCode = sessionStorage.getItem("timezone");
        if(selectedClient && selectedClient.timeZoneCode)
            timeZoneCode = selectedClient.timeZoneCode.timeZoneCode;
        else if (this.timeService.clientTimezone)
            timeZoneCode = this.timeService.clientTimezone;
        let returnStringForDisplay = '';
        switch(col.filter){
            case 'date':
                let datePropValue = element[col.name];
                if (!datePropValue)
                    datePropValue = this.getProperty(element, col.name);

                if(datePropValue) {
                    for (let i = 0, x = col.filterFormats.length; i < x; i++) {
                        if (!col.filterFormats[i].concatenator && col.filterFormats[i].useMoment) {
                            timeZoneCode = this.timeService.checkToAddMomentTimezone(undefined, timeZoneCode);
                            returnStringForDisplay = returnStringForDisplay +
                                momentTimezone(datePropValue).tz(timeZoneCode).format(col.filterFormats[i].format);
                        } else if (!col.filterFormats[i].concatenator && !col.filterFormats[i].useMoment) {
                            if (col.filterFormats[i].useUTC)
                                timeZoneCode = 'UTC';
                            try {
                                returnStringForDisplay = returnStringForDisplay +
                                    this.datePipe.transform(datePropValue, col.filterFormats[i].format, timeZoneCode, sessionStorage.getItem('locale'));
                            } catch (e) {
                                returnStringForDisplay = this.getProperty(element, col.name);
                                break;
                            }

                        } else {
                            returnStringForDisplay = returnStringForDisplay + col.filterFormats[i].format;
                        }
                    }
                } else {
                    if(element.hasOwnProperty('waitListId') && !element.hasOwnProperty('calendarId')) // is a waitlist object and thus should have the first available label
                        returnStringForDisplay = this.schedulerPreferenceService.schedulerPreference.labelMap.waitListFirstAvailableInTimeCart;
                    else
                        returnStringForDisplay = this.schedulerPreferenceService.schedulerPreference.labelMap.endDateNullLabel;
                }
                break;
            case 'boolean':
                if(element[col.name] || (col.nestedProp !== undefined && element[col.nestedProp][col.name]))
                    return '<i class="far fa-check-square"></i>';
                else
                    return '<i class="far fa-square"></i>';
                break;
            case 'time':
                let time = element[col.name];
                if (!time)
                    time = this.getProperty(element, col.name);
                returnStringForDisplay = this.militaryTime.transform(time, this.schedulerPreferenceService.schedulerPreference.timeFormat24Hrs);
                break;
            case 'chip':
                switch (element[col.name]){
                    case 'OPEN':
                    case 'Open':
                    case 'open':
                    case 'INVITED':
                    case 'invited':
                    case 'Invited':
                        returnStringForDisplay = 'primary';
                        break;
                    case 'Closed':
                    case 'CLOSED':
                    case 'closed':
                    case 'COMPLETED':
                    case 'completed':
                    case 'Completed':
                    case 'BOOKED':
                    case 'booked':
                    case 'Booked':
                        returnStringForDisplay = 'success';
                        break;
                    case 'Cancelled':
                    case 'CANCELLED':
                    case 'cancelled':
                    case 'REMOVED':
                    case 'removed':
                    case 'Removed':
                        returnStringForDisplay = 'error';
                        break;
                    case 'NO_SHOW':
                    case 'no_show':
                    case 'No_Show':
                    case 'EXPIRED':
                    case 'expired':
                    case 'Expired':
                        returnStringForDisplay = 'warning';
                        break;
                    default:
                        returnStringForDisplay = 'basic';
                }
                break;
        }
        return returnStringForDisplay;

    };

    getDefaultApptDisplayedColumns(prefixProp?: string){
        if(prefixProp === undefined)
            prefixProp = "";
        let defaultApptDisplayedColumns: string[] = [prefixProp + '%APPT_ID%',
            prefixProp + '%APPT_CLIENT_START_DATE_TIME_LONG%',
            prefixProp + '%LOCATION_NAME%',
            prefixProp + '%STAFF_FULLNAME%',
            prefixProp + '%REASON%'];
        return defaultApptDisplayedColumns
    }

    getDefaultApptColumnDefs(){
        let defaultApptColumnDefs: TableColumnDataModel[] = [
            new TableColumnDataModel('calendarId', 'Appointment ID'),
            new TableColumnDataModel('clientStartTimeDate', 'Date & Time', 'date', this.dateFileFormats),
            new TableColumnDataModel('location.locationName', this.configListService.getTitleCaseLabel('locationLabel') || 'Location'),
            new TableColumnDataModel('staff.fullName', this.configListService.getTitleCaseLabel('staffLabel') || 'Staff'),
            new TableColumnDataModel('reason.reasonDesc', this.configListService.getTitleCaseLabel('reasonLabel') || 'Reason')
        ];
        return defaultApptColumnDefs;
    }

    getDefaultRepeatingApptDisplayedColumns(prefixProp?: string){
        if(prefixProp === undefined)
            prefixProp = "";
        let defaultApptDisplayedColumns: string[] = [prefixProp + '%RECURRING_APPT_ID%',
            prefixProp + '%RECURRING_STATUS%',
            prefixProp + '%RECURRING_APPT_STARTDATE%',
            prefixProp + '%RECURRING_APPT_ENDDATE%',
            prefixProp + '%LOCATION_NAME%',
            prefixProp + '%STAFF_FULLNAME%',
            prefixProp + '%REASON%'];
        return defaultApptDisplayedColumns
    }

    getDefaultRepeatingApptColumnDefs(){
        let defaultApptColumnDefs: TableColumnDataModel[] = [
            new TableColumnDataModel('recurringAppointmentId', 'ID'),
            new TableColumnDataModel('status', 'Status', 'chip'),
            new TableColumnDataModel('startDate', 'Start Date', 'date', this.simpleDateFileFormat),
            new TableColumnDataModel('endDate', 'End Date', 'date', this.simpleDateFileFormat),
            new TableColumnDataModel('location.locationName', this.configListService.getTitleCaseLabel('locationLabel') || 'Location'),
            new TableColumnDataModel('staff.fullName', this.configListService.getTitleCaseLabel('staffLabel') || 'Staff'),
            new TableColumnDataModel('reason.reasonDesc', this.configListService.getTitleCaseLabel('reasonLabel') || 'Reason')
        ];
        return defaultApptColumnDefs;
    }

    getDefaultWaitlistRegistrationDisplayedColumns(prefixProp?: string){
        if(prefixProp === undefined)
            prefixProp = "";
        let defaultApptDisplayedColumns: string[] = [prefixProp + '%WAIT_LIST_ID%',
            prefixProp + '%WAIT_LIST_STATUS%',
            prefixProp + '%WAIT_LIST_PREFERRED_DATE%',
            prefixProp + '%LOCATION_NAME%',
            prefixProp + '%STAFF_FULLNAME%',
            prefixProp + '%REASON%'];
        return defaultApptDisplayedColumns
    }

    getDefaultWaitlistRegistrationColumnDefs(){
        let defaultApptColumnDefs: TableColumnDataModel[] = [
            new TableColumnDataModel('waitListId', 'ID'),
            new TableColumnDataModel('status', 'Status', 'chip'),
            new TableColumnDataModel('preferredDate', 'Date & Time', 'date', this.dateFileFormats),
            new TableColumnDataModel('location.locationName', this.configListService.getTitleCaseLabel('locationLabel') || 'Location'),
            new TableColumnDataModel('staff.fullName', this.configListService.getTitleCaseLabel('staffLabel') || 'Staff'),
            new TableColumnDataModel('reason.reasonDesc', this.configListService.getTitleCaseLabel('reasonLabel') || 'Reason')
        ];
        return defaultApptColumnDefs;
    }

    getDefaultPackageSoldColumns(){
        let defaultPackageSoldColumns: string[] = ['expand','pkg.packageName', 'packageSoldId', 'quantityUsedDisplay', 'status','purchaseDate','expireDate'];
        return defaultPackageSoldColumns;
    }

    getDefaultPackageSoldColumnDefs(){
        let defaultPackageSoldColumnDefs: TableColumnDataModel[] = [
            new TableColumnDataModel('pkg.packageName', 'Package Name'),
            new TableColumnDataModel('packageSoldId', 'ID'),
            new TableColumnDataModel('quantityUsedDisplay', 'Quantity Used'),
            new TableColumnDataModel('status', 'Status'),
            new TableColumnDataModel('purchaseDate', 'Purchased On', 'date', [new TableColumnDataFilterFormat('shortDate', false, false)]),
            new TableColumnDataModel('expireDate', 'Expires', 'date', [new TableColumnDataFilterFormat('shortDate', false, false)]),

        ];
        return defaultPackageSoldColumnDefs;
    }

    getDefaultPaymentInfoColumns(){
        let defaultPackageSoldColumns: string[] = ['brand','last4', 'expiration', 'zip', 'actions'];
        return defaultPackageSoldColumns;
    }
    getDefaultPaymentInfoColumnDefs(){
        let defaultPackageSoldColumnDefs: TableColumnDataModel[] = [
            new TableColumnDataModel('brand', 'Card Type'),
            new TableColumnDataModel('last4', 'Last 4'),
            new TableColumnDataModel('expiration', 'Expiration'),
            new TableColumnDataModel('zip', 'Zip Code'),
        ];
        return defaultPackageSoldColumnDefs;
    }

    getDefaultDisclaimerFormColumns(){
        let defaultDisclaimerFormColumns: string[] = ['formName','required', 'status', 'expiresBy', 'actions'];
        return defaultDisclaimerFormColumns;
    }
    getDefaultDisclaimerFormColumnDefs(){
        let defaultPackageSoldColumnDefs: TableColumnDataModel[] = [
            new TableColumnDataModel('formName', 'Form Name'),
            new TableColumnDataModel('required', 'Required', 'boolean'),
            new TableColumnDataModel('status', 'Status'),
            new TableColumnDataModel('expiresBy', 'Expires', 'date', [new TableColumnDataFilterFormat('shortDate', false, false)]),
        ];
        return defaultPackageSoldColumnDefs;
    }

    getDefaultEmailSettingsColumns(){
        let defaultDisclaimerFormColumns: string[] = ['displayName','type', 'selected', 'actions'];
        return defaultDisclaimerFormColumns;
    }
    getDefaultEmailSettingsColumnDefs(){
        let defaultPackageSoldColumnDefs: TableColumnDataModel[] = [
            new TableColumnDataModel('displayName', 'Email Category / Template Name'),
            new TableColumnDataModel('type', 'Type'),
            new TableColumnDataModel('selected', 'Subscribed', 'boolean'),
        ];
        return defaultPackageSoldColumnDefs;
    }

    getDisplayedColumns(preferenceDefinedColumns: string[],defaultColumnDefs: TableColumnDataModel[], preference: preference, onMobileViewPort: boolean, columnToAdd?: string){

        let columnsForTable: {columns: TableColumnDataModel[], appointmentFieldList: string[]};
        if(columnToAdd && preferenceDefinedColumns.indexOf(columnToAdd)=== -1)
            preferenceDefinedColumns.push(columnToAdd);
        columnsForTable = this.tagsService.getApptListColumnValues(preferenceDefinedColumns, preference.schedulerPreferenceFieldDefnList,
            preference.labelMap, onMobileViewPort, 'apptFieldTags');
        columnsForTable.columns = this.setupColumnDefs(defaultColumnDefs, columnsForTable.columns);
        return columnsForTable;
    }

    getDisplayedAppointmentIntentColumns(preferenceDefinedColumns: string[],defaultColumnDefs: TableColumnDataModel[], preference: preference, onMobileViewPort: boolean, columnToAdd?: string){

      let columnsForTable: {columns: TableColumnDataModel[], appointmentFieldList: string[]};
      if(columnToAdd && preferenceDefinedColumns.indexOf(columnToAdd)=== -1)
          preferenceDefinedColumns.push(columnToAdd);
      columnsForTable = this.tagsService.getApptIntentListColumnValues(preferenceDefinedColumns, preference.schedulerPreferenceFieldDefnList,
          preference.labelMap, onMobileViewPort, 'appointmentIntentFieldTags');
      columnsForTable.columns = this.setupColumnDefs(defaultColumnDefs, columnsForTable.columns);
      return columnsForTable;
    }

    setupColumnDefs = function(defaultColumnDefs: TableColumnDataModel[], tableColumnDefs: TableColumnDataModel[]){
        let fieldsCurrentlyInColumnDefs: string[] = [];
        for(let i = 0, x = defaultColumnDefs.length; i < x; i++)
            fieldsCurrentlyInColumnDefs.push(defaultColumnDefs[i].name);
        for(let i = 0, x = tableColumnDefs.length; i < x; i++){
            let columnFound = false;
            for(let j = 0, y = defaultColumnDefs.length; j < y; j++) {
                if(tableColumnDefs[i].name === defaultColumnDefs[j].name) {
                    defaultColumnDefs[j].title = tableColumnDefs[i].title;
                    tableColumnDefs[i] = defaultColumnDefs[j];
                    columnFound = true;
                }
            }
            for(let j = 0, y = this.commonTableDataColumns.length; j < y; j++){
                if(tableColumnDefs[i].name === this.commonTableDataColumns[j].name){
                    this.commonTableDataColumns[j].title = tableColumnDefs[i].title;
                    tableColumnDefs[i] = this.commonTableDataColumns[j];
                    columnFound = true;
                }
            }
            if(!columnFound){
                // tableColumnDefs[i].filter = null; -- LHB 05/21/2021 commented out as part of fix for TT-7810
            }
        }
        return tableColumnDefs;
    }

    addCustomFieldsToListDataObjects = function(dataObject: any, selectedClient: Client){
        //Loop through appointment and client fields to add them to the flat appt object in case they have them on display as columns in the list view
        if(dataObject.fields !== undefined && dataObject.fields !== null) {
            for (let j = 0, y = dataObject.fields.length; j < y; j++) {
                let apptPropLabel = "field_" + dataObject.fields[j].schedulerPreferenceFieldDefnId;
                if(dataObject.fields[j].dataType === 'FILEUPLOAD'){
                    dataObject[apptPropLabel] = "<a href='" + dataObject.fields[j].extendedValue + "' target='_blank'>Open File</a>";
                } else {
                    dataObject[apptPropLabel] = dataObject.fields[j].value;
                }
            }
        }
        if(selectedClient !== undefined && selectedClient.fields !== undefined && selectedClient.fields !== null && selectedClient.fields.length > 0){
            for (let j = 0, y = selectedClient.fields.length; j < y; j++) {
                let apptPropLabel = "field_" + selectedClient.fields[j].schedulerPreferenceFieldDefnId;
                if(selectedClient.fields[j].dataType === 'FILEUPLOAD'){
                    dataObject[apptPropLabel] = "<a href='" + selectedClient.fields[j].extendedValue + "' target='_blank'>Open File</a>";
                } else {
                    dataObject[apptPropLabel] = selectedClient.fields[j].value;
                }
            }
        }
        return dataObject;
    }

    getDefaultAppointmentIntentColumnDefs(){
      let defaultAppointmentIntentColumnDefs: TableColumnDataModel[] = [
        new TableColumnDataModel( 'appointmentIntentId', (this.configListService.getTitleCaseLabel('appointmentIntentLabel') || 'Appointment Intent') + ' ID'),
        new TableColumnDataModel('startDate', 'Start Date', 'date', [new TableColumnDataFilterFormat('MM/dd/yyyy', false, false, true)]),
        new TableColumnDataModel('endDate', 'End Date', 'date', [new TableColumnDataFilterFormat('MM/dd/yyyy', false, false, true)]),
        new TableColumnDataModel('externalReferenceNumber', 'External Reference Number'),
      ];
      return defaultAppointmentIntentColumnDefs;
    }
    getDefaultAppointmentIntentDisplayedColumns(prefixProp?: string){
      if(prefixProp === undefined)
          prefixProp = "";
      let defaultAppointmentIntentDisplayedColumns: string[] = [
          prefixProp + '%APPT_INTENT_START_DATE_TIME_LONG%',
          prefixProp + '%APPT_INTENT_END_DATE_TIME_LONG%',
          prefixProp + '%EXTERNAL_REFERENCE_NUMBER%',];
      return defaultAppointmentIntentDisplayedColumns;
    }
}
