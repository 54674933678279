import {
    DatePipe,
    FormatWidth,
    FormStyle,
    getLocaleDateFormat,
    getLocaleDayNames,
    TranslationWidth
} from '@angular/common';
import {Injectable, Injector} from "@angular/core";
import {MonthDataObj, MonthDay, preference, RecurringAppointment} from "../../common-classes/app-objects.model";
import * as moment from "moment/moment";
import * as momentTimezone from "moment-timezone/moment-timezone";
import {MonthAvailability, SingleTimeSlot, TimeZoneCode, WeekDayData} from "./time-objects.model";
import {Moment} from "moment";
import {MomentTimezoneService} from './moment-timezones.service';
import {Group} from '../../../models/Group.model';
import {Appointment} from '../../../models/Appointment.model';
import {WaitList} from '../../../models/WaitList.model';
import {FormatFunctionsService} from '../../format-functions.service';
import {ConfigListService} from '../../config-list.service';
import {Reason, ReasonTypes} from '../../../models/Reason.model';
import {ClassSession} from '../../../models/ClassSession.model';
import {Location} from '../../../models/Location.model';
import {Staff} from '../../../models/Staff.model';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {SelectedDateTimeObj} from '../../../models/SelectedDateTimeObj.model';
import {FormGroup} from '@angular/forms';
import { AppointmentIntent } from '../../../models/AppointmentIntent.model';

@Injectable()

export class TimeService {
    locale: string;
    oneHrMs = 1000 * 60 * 60;
    oneDayMs = this.oneHrMs * 24;
    repeatingApptSelectedStartDate: Date = new Date();
    repeatingApptSelectedDays: WeekDayData[] = [];
    businessTimezone: string;
    clientTimezone: string; // LHB 10/16/2020 TT-7087
    formatFnsService: FormatFunctionsService;
    configListService: ConfigListService;
    schedulerPreferenceService: SchedulerPreferenceService;
    constructor(private datePipe: DatePipe, private momentTimezoneService: MomentTimezoneService, private injector: Injector){
        try {
            this.locale = sessionStorage.getItem('locale');
        } catch (e) {
           this.locale = 'en-US';
        }
        moment.locale(this.locale);
        this.formatFnsService = injector.get(FormatFunctionsService);
        this.configListService = injector.get(ConfigListService);
        this.schedulerPreferenceService = injector.get(SchedulerPreferenceService);
    }


    setBusinessTimezone(businessTimezone: string){
        this.businessTimezone = businessTimezone;
    }

    setClientTimezone(clientTimezone: string){ // LHB 10/16/2020 TT-7087
        this.clientTimezone = clientTimezone;
    }

    getMomentFromDate(dateForMoment: Date){
        return moment(dateForMoment);
    }

    checkToAddMomentTimezone(allowTZChange?: number, timeZoneForMoment?: string){
        timeZoneForMoment = this.checkForValidMomentTimezone(timeZoneForMoment);
        // let browserTimezone = sessionStorage.getItem("browserTimezone")
        if (momentTimezone.tz.zone(timeZoneForMoment) === null) {
            let foundTimezone = false;
            for (let i = 0, x = this.momentTimezoneService.allTimezones.zones.length; i < x; i++) {
                if (this.momentTimezoneService.allTimezones.zones[i].indexOf(timeZoneForMoment) !== -1) {
                    foundTimezone = true;
                    momentTimezone.tz.add(this.momentTimezoneService.allTimezones.zones[i]);
                }
            }
        }
        return timeZoneForMoment;
    }

    checkToAddMomentTimezoneDefaultSchedulerPreference(timeZoneForMoment?: string){
        return this.checkToAddMomentTimezone(this.schedulerPreferenceService.schedulerPreference.allowTZChange, timeZoneForMoment);
    }

    checkForValidMomentTimezone(timeZoneCode?: string){
        let timeZoneForMoment = timeZoneCode || sessionStorage.getItem("browserTimezone");
        for(let i = 0, x = this.momentTimezoneService.allTimezones.links.length; i < x; i++){
            if (this.momentTimezoneService.allTimezones.links[i].indexOf(timeZoneForMoment) !== -1) {
                let indexOfPipe = this.momentTimezoneService.allTimezones.links[i].indexOf("|");
                timeZoneForMoment = this.momentTimezoneService.allTimezones.links[i].substring(0,indexOfPipe);
            }
        }
        return timeZoneForMoment;
    }

    /**
     * Added in method below in order to convert appointment returned from backend into clients timezone for display properly
     * 05/05/2020 - TT-6552
     */
    getTimeWithMomentForClientConvertedTimezone(selectedTime: number, selectedDate: string, timeZoneForMoment: string, allowTZChange?: number){
        if(allowTZChange === undefined || allowTZChange === null)
            allowTZChange = 0;
        timeZoneForMoment = this.checkToAddMomentTimezone(allowTZChange, timeZoneForMoment);
        let hoursAndMinutes = this.getHoursAndMinutes(selectedTime);
        let minutes = hoursAndMinutes.minutes.toString();
        if(minutes == "0")
            minutes = "00";
        let dateTime = selectedDate + " " + hoursAndMinutes.hour + ":" + minutes;
        let dateToReturn = momentTimezone.tz(dateTime, timeZoneForMoment);
        return dateToReturn;
    }

    getTimeWithMoment(selectedTime: number, selectedDate: string | Date, allowTZChange: number, timeZoneForMoment?: string, time?: SingleTimeSlot){
        timeZoneForMoment = this.checkToAddMomentTimezone(allowTZChange, timeZoneForMoment);
        let dateTime = this.convertStringDateToObject(selectedDate, selectedTime);
        return momentTimezone(dateTime).tz(timeZoneForMoment)
    }

    getTimeFromUTCTimeInClientTimezone(utcTime: number | Date, timeZoneForMoment: string){
        timeZoneForMoment = this.checkToAddMomentTimezone(0, timeZoneForMoment);
        let dataToReturn = momentTimezone.utc(utcTime).locale('en').tz(timeZoneForMoment).format();
        return dataToReturn;
    }

    getUTCDateFromMoment(moment: Moment) {
        const utcMoment = moment.utc();
        return new Date( utcMoment.format());
    }

    getMomentDaysDiff(firstDate: string | Moment, secondDate: Moment){
        // console.log("firstDate");
        // console.log(firstDate);
        // console.log("secondDate");
        // console.log(secondDate);
        if (typeof firstDate === 'string')
            firstDate = moment(this.convertStringDateToObject(firstDate, 0))
        let difference = firstDate.diff(secondDate);
        // console.log("difference");
        // console.log(difference);
        let duration = moment.duration(difference);
        // console.log("duration");
        // console.log(duration);
        let daysDiff = duration.asDays();
        return Math.ceil(daysDiff);
    }



    getWeekdayDataObjs(translationWidthType?: string): WeekDayData[]{
        let weekdayDataObjs: WeekDayData[] = [];
        if(translationWidthType === undefined)
            translationWidthType = "Wide"
        let weekdays = this.getWeekdayData(translationWidthType);
        let firstDayOfWeek = moment().localeData().firstDayOfWeek();
        for(let i = firstDayOfWeek, x = weekdays.length; i < x; i++){
            let weekDayDataObj: WeekDayData = new WeekDayData();
            weekDayDataObj.dayOfWeekInt = i;
            weekDayDataObj.dayOfWeekName = weekdays[i];
            weekDayDataObj.selected = false;
            weekdayDataObjs.push(weekDayDataObj);
        }
        for(let i = 0; i < firstDayOfWeek; i++){
            let weekDayDataObj: WeekDayData = new WeekDayData();
            weekDayDataObj.dayOfWeekInt = i;
            weekDayDataObj.dayOfWeekName = weekdays[i];
            weekDayDataObj.selected = false;
            weekdayDataObjs.push(weekDayDataObj);
        }
        return weekdayDataObjs;
    }

    getWeekdayData(translationWidthType?: string){
        if(translationWidthType === undefined)
            translationWidthType = "Abbreviated";
        let formStyle = FormStyle["Format"];
        let translationWidth = TranslationWidth[translationWidthType];
        let weekdays = getLocaleDayNames(this.locale, formStyle, translationWidth);
        // let firstDayOfWeek = getLocaleFirstDayOfWeek(this.locale);
        let firstDayOfWeek = moment().localeData().firstDayOfWeek();
        let weekdaysForLocale = [];
        for(let i = firstDayOfWeek, x = weekdays.length; i < x; i++){
            weekdaysForLocale.push(weekdays[i])
        }
        for(let i = 0; i < firstDayOfWeek; i++){
            weekdaysForLocale.push(weekdays[i])
        }
        return weekdaysForLocale;
    }

    getSelectedWeekdays(repeatingAppt: RecurringAppointment, weekdays: WeekDayData[]): WeekDayData[]{
        let weekdaysToReturn: WeekDayData[] = [];
        for(let i = 0, x = weekdays.length; i < x; i++){
            switch (weekdays[i].dayOfWeekInt){
                case 0:
                    if(repeatingAppt.sun)
                        weekdays[i].selected = true;
                    else
                        weekdays[i].selected = false;
                    break;
                case 1:
                    if(repeatingAppt.mon)
                        weekdays[i].selected = true;
                    else
                        weekdays[i].selected = false;
                    break;
                case 2:
                    if(repeatingAppt.tue)
                        weekdays[i].selected = true;
                    else
                        weekdays[i].selected = false;
                    break;
                case 3:
                    if(repeatingAppt.wed)
                        weekdays[i].selected = true;
                    else
                        weekdays[i].selected = false;
                    break;
                case 4:
                    if(repeatingAppt.thu)
                        weekdays[i].selected = true;
                    else
                        weekdays[i].selected = false;
                    break;
                case 5:
                    if(repeatingAppt.fri)
                        weekdays[i].selected = true;
                    else
                        weekdays[i].selected = false;
                    break;
                case 6:
                    if(repeatingAppt.sat)
                        weekdays[i].selected = true;
                    else
                        weekdays[i].selected = false;
                    break;
            }
        }
        for(let i = 0, x = weekdays.length; i < x; i++){
            if(weekdays[i].selected)
                weekdaysToReturn.push(weekdays[i]);
        }
        return weekdaysToReturn;
    }

    getTimeString(time){
        let timeString: string = time.toString();
        let hour: string = '';
        let minutes: string = '';
        if(timeString.length===3){
            hour = timeString.substring(0,1);
            minutes = timeString.substring(1,3);
        } else if (timeString.length === 1){
            hour = "00";
            minutes = "0" + timeString;
        } else if(timeString.length === 2){
            //if it's 2, then it's going to be 12 am
            hour = "00";
            minutes = timeString;
        } else {
            hour = timeString.substring(0,2);
            minutes = timeString.substring(2,4);
        }

        if(hour !== "00"){
            hour = parseInt(hour).toString();
        }
        return {'hourNum': Number(hour), 'minuteNum': Number(minutes)}
    }

    getPipeYearFromDate(date: string | number | Date, timezone?: string){
        if(timezone == undefined || timezone == null)
            timezone = 'UTC';
        return this.datePipe.transform(date, 'yyyy', timezone, this.locale);
    }

    getMonthAbbrWithDatePipe(date: string | number | Date, useUTC?: boolean) {
        if (useUTC)
            return this.datePipe.transform(date, 'MMM', 'UTC', this.locale);
        else
            return this.datePipe.transform(date, 'MMM', undefined, this.locale);
    }

    getPipeMonthFromDate(date: string | number | Date, timezone?: string){
        if(timezone == undefined || timezone == null)
            timezone = 'UTC';
        return this.datePipe.transform(date, 'MM', timezone, this.locale);
    }

    getPipeDayFromDate(date: string | number | Date, timezone?: string){
        if(timezone == undefined || timezone == null)
            timezone = 'UTC';
        return this.datePipe.transform(date, 'dd', timezone, this.locale);
    }

    getPipeHourFromDate(date: Date){
        return this.datePipe.transform(date, 'HH', 'UTC', this.locale);
    }

    getPipeMinutesFromDate(date: Date){
        return this.datePipe.transform(date, 'mm', 'UTC', this.locale);
    }

    getPipeDayOfWeekFromDate(date: Date){
        return this.datePipe.transform(date, 'EEEE', 'UTC', this.locale);
    }

    getShortDateFromDate(date: string | number | Date, timezone?: string){
        if(!timezone)
            timezone = 'UTC';

        return this.datePipe.transform(date, 'shortDate', timezone, this.locale);
    }

    getShortDateFromDateNoConversion(date: Date){
        var defaultFormat = getLocaleDateFormat(this.locale, FormatWidth.Short);
        var month = date.getMonth() + 1;

        var day = date.getDate();
        var year = date.getFullYear();


        var monthStr = (month < 10? '0'+month : month);
        var dayStr = (day < 10? '0'+day : day);
        defaultFormat = defaultFormat.replace('dd', dayStr+'');
        defaultFormat = defaultFormat.replace('d', dayStr+'');
        defaultFormat = defaultFormat.replace('MM', monthStr+'');
        defaultFormat = defaultFormat.replace('M', monthStr+'');
        defaultFormat = defaultFormat.replace('mm', monthStr+'');
        defaultFormat = defaultFormat.replace('m', monthStr+'');
        defaultFormat = defaultFormat.replace('yyyy', year+'');
        defaultFormat = defaultFormat.replace('yyy', year+'');
        defaultFormat = defaultFormat.replace('yy', year+'');
        defaultFormat = defaultFormat.replace('y', year+'');
        return  defaultFormat;
    }



    getShortTimeWithDatePipe(date: string | number | Date, useUTC?: boolean): string {
        if (useUTC)
            return this.datePipe.transform(date, 'shortTime', 'UTC', this.locale);
        else
            return this.datePipe.transform(date, 'shortTime', undefined, this.locale);
    }

    getStringDateFromTime(date: string | number | Date): string{
        return this.getPipeYearFromDate(date) + '-' + this.getPipeMonthFromDate(date) + '-' + this.getPipeDayFromDate(date);
    }

    getStringDateFromMSUTCTime(date: number){
        return this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC', this.locale);
    }

    getMilitaryHoursWithDatePipe(date: string | number | Date, useUTC?: boolean) {
        if (useUTC)
            return this.datePipe.transform(date, 'H', 'UTC', this.locale);
        else
            return this.datePipe.transform(date, 'H', undefined, this.locale);
    }

    getMinutesWithDatePipe(date: string | number | Date, useUTC?: boolean) {
        if (useUTC)
            return this.datePipe.transform(date, 'mm', 'UTC', this.locale);
        else
            return this.datePipe.transform(date, 'mm', undefined, this.locale);
    }

    moveToNextMonth(currentMonthData: MonthDataObj, activeYear: number, monthsData: MonthDataObj[], activeMonth: number){
        if (currentMonthData.monthNumberIndex === 11) {
            activeYear = activeYear + 1;
            monthsData = this.getMonthsData(activeYear);
            activeMonth = 1;
        } else {
            activeMonth = activeMonth + 1;
        }
        return {activeYear: activeYear, activeMonth: activeMonth, monthsData: monthsData};
    }


    calculateDateOfMonth(currentYear: number, monthNumber: number, dayNumber: number){
        let objectToReturn = {startDayOfWeek: '', startDayOfWeekNum: 0};
        let firstDateOfMonth = new Date(currentYear, monthNumber, dayNumber);
        objectToReturn.startDayOfWeek = this.datePipe.transform(firstDateOfMonth, 'EEE', 'UTC');
        objectToReturn.startDayOfWeekNum = firstDateOfMonth.getDay();
        return objectToReturn;
    }

    getMonthsData(currentYear: number){
        let monthsData: MonthDataObj[] = [
            new MonthDataObj('January', 'jan', 31, 1, 0, currentYear, '', 0, new Date(currentYear, 0)),
            new MonthDataObj('February', 'feb', 28, 2, 1, currentYear, '', 0, new Date(currentYear, 1)),
            new MonthDataObj('March', 'mar', 31, 3, 2, currentYear, '', 0, new Date(currentYear, 2)),
            new MonthDataObj('April', 'apr', 30, 4, 3, currentYear, '', 0, new Date(currentYear, 3)),
            new MonthDataObj('May', 'may', 31, 5, 4, currentYear, '', 0, new Date(currentYear, 4)),
            new MonthDataObj('June', 'jun', 30, 6, 5, currentYear, '', 0, new Date(currentYear, 5)),
            new MonthDataObj('July', 'jul', 31, 7, 6, currentYear, '', 0, new Date(currentYear, 6)),
            new MonthDataObj('August', 'aug', 31, 8, 7, currentYear, '', 0, new Date(currentYear, 7)),
            new MonthDataObj('September', 'sept', 30, 9, 8, currentYear, '', 0, new Date(currentYear, 8)),
            new MonthDataObj('October', 'oct', 31, 10, 9, currentYear, '', 0, new Date(currentYear, 9)),
            new MonthDataObj('November', 'nov', 30, 11, 10, currentYear, '', 0, new Date(currentYear, 10)),
            new MonthDataObj('December', 'dec', 31, 12, 11, currentYear, '', 0, new Date(currentYear, 11))
        ];
        let changeFebruaryDays = currentYear % 4;
        if(changeFebruaryDays % 4 === 0){
            monthsData[1].daysInMonth = 29;
        }
        //GET THE START DATE OF EACH MONTH
        for(let i = 0, x = monthsData.length; i < x; i++){
            let startDateObject = this.calculateDateOfMonth(currentYear, monthsData[i].monthNumberIndex, 1);
            monthsData[i].startDayOfWeek = startDateObject.startDayOfWeek;
            monthsData[i].startDayOfWeekNum = startDateObject.startDayOfWeekNum;
        }
        return monthsData;
    }

    getDaysOfMonth(currentYear: number, savedMonth: number, availability: MonthAvailability){
        let monthStartDay: Date = new Date(currentYear, savedMonth - 1, 1);
        let monthStart: number = new Date(currentYear, savedMonth - 1, 1).getTime();
        let monthEnd: number = new Date(currentYear, savedMonth, 1).getTime();
        let monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
        monthLength = Math.round(monthLength);
        let days: MonthDay[] = [];
        let startWkDay = monthStartDay.getDay();

        // let firstDayOfWeek = getLocaleFirstDayOfWeek(this.locale);
        let firstDayOfWeek = moment().localeData().firstDayOfWeek();

        if(firstDayOfWeek > startWkDay){
            let difference = 7 - firstDayOfWeek; //has to always equal 7 for days of the week; using 7 here since instead of finding the index, we are finding the difference to get the index; $rootScope.firstDayOfWeek is what we use for the locale's first day of week, to display calendar on correct day
            startWkDay = monthStartDay.getDay() + difference;
        } else if(firstDayOfWeek < startWkDay){
            let difference = startWkDay - firstDayOfWeek;
            startWkDay = difference;
        } else if(firstDayOfWeek === 1 && startWkDay === 1) {
            startWkDay = 0;
        }
        for(let a = 0; a < monthLength + startWkDay; a++){
            if(a >= startWkDay){
                days[a] = {dayNumInMonth: (a-startWkDay)+1, available: undefined, isDayInMonth: true};
                if(days[a].dayNumInMonth.toString().length===1){ //append 0 to single digit days to make width uniform
                    let dayNumInMonth = "0" + days[a].dayNumInMonth.toString();
                    days[a].dayNumInMonth = Number(dayNumInMonth);
                }
            } else {
                days[a] = {dayNumInMonth: 0, available: undefined, isDayInMonth: false};
            } //end if
        } //end for
        for(let c = 0; c < days.length; c++){
            if (availability && availability.openDays) {
                for(let d = 0; d < availability.openDays.length; d++){
                    if(availability.openDays[d].day === days[c].dayNumInMonth){
                        days[c].available = true;
                    }
                }
            }
        };
        return days;
    }

    getHoursAndMinutes(time: number){
        if(time !== undefined && time !== null){
            let returnString = time.toString();
            let hour: string;
            let minutes: string;

            if(returnString.length===3){
                hour = returnString.substring(0,1);
                minutes = returnString.substring(1,3);
            } else if (returnString.length === 1){
                hour = "00";
                minutes = "0" + returnString;
            } else if(returnString.length === 2){
                //if it's 2, then it's going to be 12 am
                hour = "00";
                minutes = returnString;
            } else {
                hour = returnString.substring(0,2);
                minutes = returnString.substring(2,4);
            }

            if(hour !== "00"){
                hour = parseInt(hour).toString();
            }

            return {hour: Number(hour), minutes: Number(minutes)};
        }
    }

    getMonthDayIndexForStringDate(date: string): {monthIndexEnd: number, dayIndex: number}{
        let returnObject = {monthIndexEnd: 7, dayIndex: 8};
        if(date.length <= 9){ // so it's 2020-7-22 instead of 2020-07-22
            returnObject.monthIndexEnd = 6;
            returnObject.dayIndex = 7;
        }
        return returnObject;
    }

    dateWithTimeZone(timeZone: string, dateToConvert: string | Date, time: number)  {
        if (dateToConvert instanceof Date)
            dateToConvert = this.getStringDateFromTime(dateToConvert);
        dateToConvert = String(dateToConvert);
        let year = Number(dateToConvert.substring(0,4)),
            month = Number(dateToConvert.substring(5, this.getMonthDayIndexForStringDate(dateToConvert).monthIndexEnd)) - 1,
            day = Number(dateToConvert.substring(this.getMonthDayIndexForStringDate(dateToConvert).dayIndex)),
            hoursAndMinutes = this.getHoursAndMinutes(time),
            hour = hoursAndMinutes.hour,
            minute = hoursAndMinutes.minutes;
        let date = new Date(Date.UTC(year, month, day, hour, minute, 0));

        let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));
        let tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
        let offset = utcDate.getTime() - tzDate.getTime();

        date.setTime( date.getTime() + offset );

        return date;
    };

    convertStringDateToObject = function(date: string | Date, time: number){
        if (date instanceof Date)
            date = this.getStringDateFromTime(date);
        date = String(date);
        let startDateYear = Number(date.substring(0,4)),
            startDateMonth = Number(date.substring(5, this.getMonthDayIndexForStringDate(date).monthIndexEnd)) - 1,
            startDateDay = Number(date.substring(this.getMonthDayIndexForStringDate(date).dayIndex)),
            hoursAndMinutes = this.getHoursAndMinutes(time),
            hour = hoursAndMinutes.hour,
            minutes = hoursAndMinutes.minutes;
        let dateToReturn = new Date(startDateYear, startDateMonth, startDateDay, hour, minutes);
        return dateToReturn;
    }

    convertStringDateToUTCObject = function(date: string, time: number){
        let startDateYear = Number(date.substring(0,4)),
            startDateMonth = Number(date.substring(5,this.getMonthDayIndexForStringDate(date).monthIndexEnd)) - 1,
            startDateDay = Number(date.substring(this.getMonthDayIndexForStringDate(date).dayIndex)),
            hoursAndMinutes = this.getHoursAndMinutes(time),
            hour = hoursAndMinutes.hour,
            minutes = hoursAndMinutes.minutes;
        return Date.UTC(startDateYear, startDateMonth, startDateDay, hour, minutes);
    }

    /**
     * Check to get timezone that would have been applied to the appointment in hierarchial order
     */
    getApptTimezone(location: Location, locationGroup: Group, staff: Staff): string{
        // if location timezone is filled in, then use that
        if(location !== undefined && location !== null && location.timezone !== undefined && location.timezone !== null)
            return location.timezone;
        else if(locationGroup !== undefined && locationGroup !== null && locationGroup.timeZoneCode !== undefined && locationGroup.timeZoneCode !== null)
            return locationGroup.timeZoneCode.timeZoneCode;
        else if(staff !== undefined && staff !== null && staff.timezone !== undefined && staff.timezone !== null)
            return staff.timezone;
        else
            return this.businessTimezone;
    }

    getAllowGetMonth(year: number, month: number, maxBookingDate: Date, minBookingDate: Date){
        let allowGetMonth = false;
        let startDateMonthMs = new Date(year, month - 1).getTime();
        let endDateMonthMs = new Date(year, month).getTime();
        let isStartDateLessThanMaxBookingDate = startDateMonthMs <= maxBookingDate.getTime();
        let isEndDateGreaterThanMinBookingDate = endDateMonthMs >= minBookingDate.getTime();
        if(isStartDateLessThanMaxBookingDate && isEndDateGreaterThanMinBookingDate){
            allowGetMonth = true;
        }
        return allowGetMonth;
    }

    getDateTimeValue(apptData: Appointment, timezone: string, dateTimeUTCProp: string, dateProp: string, timeProp: string, fallBackDateTimeUTCProp?: string){
        if(apptData[dateTimeUTCProp] !== undefined && apptData[dateTimeUTCProp] !== null) {
            return this.getTimeFromUTCTimeInClientTimezone(apptData[dateTimeUTCProp], timezone);
        } else if(fallBackDateTimeUTCProp !== undefined && apptData[fallBackDateTimeUTCProp] !== undefined && apptData[fallBackDateTimeUTCProp] !== null)
            return this.getTimeFromUTCTimeInClientTimezone(apptData[fallBackDateTimeUTCProp], timezone);
        else if(apptData[dateProp] !== undefined && apptData[dateProp] !== null){
            if(apptData[timeProp] === undefined || apptData[timeProp] === null)
                apptData[timeProp] = 0;
            if(typeof apptData[dateProp] === 'string')
                return this.convertStringDateToObject(apptData[dateProp], apptData[timeProp]);
            else
                return this.getTimeWithMomentForClientConvertedTimezone(apptData[timeProp], apptData[dateProp], timezone);
        }
    }

    getApptDateTimeDisplayPropertiesWithTimezone(apptData: Appointment, allowTZChange: number){
        let timezone = this.getApptTimezone(apptData.location, apptData.locationGroup, apptData.staff);
        if (allowTZChange === 1) { // LHB 10/16/2020 TT-7087
            if (this.clientTimezone)
                timezone = this.clientTimezone;
            if (apptData.client && apptData.client.timeZoneCode)
                timezone = apptData.client.timeZoneCode.timeZoneCode;
        }

        apptData.staffStartTimeDate = this.getDateTimeValue(apptData, timezone, 'startDateTimeUTC', 'startDate','startTime');
        apptData.staffEndTimeDate = this.getDateTimeValue(apptData, timezone, 'endDateTimeUTC', 'endDate','endTime');
        apptData.clientStartTimeDate = this.getDateTimeValue(apptData, timezone, 'clientStartDateTimeUTC', 'clientStartDate','clientStartTime', 'startDateTimeUTC');
        apptData.clientEndTimeDate = this.getDateTimeValue(apptData, timezone, 'clientEndDateTimeUTC', 'clientEndDate','clientEndTime', 'endDateTimeUTC');

        return apptData;
    }

    getHoursAndMinutesFromTime(time: number): {hours: number, mins: number} {
        let returnObject = {hours: 8, mins: 0};
        if (time !== undefined && time !== null) {
            const timeString = time.toString();
            let hours = 0;
            let mins = 0;
            if (timeString.length === 1)
                mins = 0 + Number(timeString);
            else if (timeString.length === 2)
                mins = Number(timeString);
            else if (timeString.length === 3) { // TIME IS BEFORE 10AM AND THUS 3 DIGITS IN LENGTH
                hours = Number(timeString.charAt(0));
                mins = Number(timeString.slice(1, 3));
            } else if (timeString.length === 4) { // I.E. "1100"
                hours = Number(timeString.slice(0, 2));
                mins = Number(timeString.slice(2, 4));
            } else if (timeString.length === 5) { // I.E. "11:00"
                hours = Number(timeString.substring(0, 2));
                mins = Number(timeString.substring(3, 5));
            } else if(timeString.length === 7 || timeString.length === 8) { // I.E. "9:00 AM" (7) or "12:00 PM" (8)
                const amPm = timeString.substring(6);
                if (timeString.length === 7) {
                    hours = Number(timeString.substring(0, 1));
                    mins = Number(timeString.substring(2, 4));
                } else if (timeString.length === 8) {
                    hours = Number(timeString.substring(0, 2));
                    mins = Number(timeString.substring(3, 5));
                }
                if (amPm === 'PM')
                    hours = hours + 12;
            }
            returnObject = {hours, mins};
        }
        return returnObject;
    }

    roundApptTimesToHourForSave(time: number, path?: string): number {
        const timeObject = this.getHoursAndMinutesFromTime(time);
        let hours = timeObject.hours;
        let minutes = timeObject.mins;
        if (minutes === 0 && path === 'subtracting') {
            minutes = 60;
            if (hours === 0)
                hours = 24;
            hours = hours - 1;
        } else if (minutes === 60) {
            minutes = 0;
            hours = hours + 1;
        }
        if (path !== 'subtracting' && hours === 24)
            hours = 0;
        let minutesString = String(minutes);
        if (minutesString.length === 1)
            minutesString = 0 + '' + minutes;
        let timeToReturn = hours + '' + minutesString;
        if (timeToReturn === '2360' || timeToReturn === '2400')
            timeToReturn = '0';
        return Number(timeToReturn);
    }

    calculateEndTime(minutes: number, startTime: number, roundDirection: 'roundUp' | 'roundDown') {
        const minutesToAdd = minutes % 60;
        let hoursToAdd;
        if (minutesToAdd !== 0) // MEANS SERVICE IS NOT AN EVEN HOUR
            if (Math.abs(minutes) > 60) {
                hoursToAdd = Math.floor(Math.abs(minutes) / 60);
                if (minutes < 0)
                    hoursToAdd = hoursToAdd * -1;
            } else
                hoursToAdd = 0;
        else
            hoursToAdd = minutes / 60;
        if (minutesToAdd < 0) // IF NEEDING TO SUBTRACT TIME, ROUND START TIME THAT IS ON HOUR (LIKE 0800) BACK TO TOP OF LAST HUNDRED INCREMENT (SO 0760)
            startTime = this.roundApptTimesToHourForSave(startTime);
        let endTime = startTime + hoursToAdd * 100 + minutesToAdd;
        if (endTime >= 60 && endTime <= 99) // FIX FOR TT-3473
            endTime = endTime + 40;
        if (endTime === 2400)
            endTime = 0;
        return this.roundBackToHour(endTime, roundDirection);
    }

    roundBackToHour(time: number, roundDirection: 'roundUp' | 'roundDown') { // THIS IS A COMMON FUNCTION TO HANDLE WHEN END TIME IS RIGHT ON HOUR AND GOES TO HH60
        // NOTICED AN ERROR WHERE IF A SERVICE IS 30 MINUTES IN DURATION AND A 30 MINUTE BUFFER, IF CHANGED START TIME FROM 12:25 PM TO 12:30 PM, THE END TIME WAS GETTING SET TO 1260; THUS NEED TO CHECK AND THEN ROUND THE HOUR UP
        const timeString = time.toString();
        let minutes;
        if (timeString.length === 3) // TIME IS BEFORE 10:00am
            minutes = timeString.slice(1);
        else if (timeString.length === 4) // TIME IS AFTER 10:00AM
            minutes = timeString.slice(2);
        minutes = Number(minutes);
        if (minutes >= 60)  // IF IT DID GET ROUNDED OVER 60, THEN ADD 100 TO IT TO GO TO NEXT HOUR AND SUBTRACT THE 60 TO GET THE RIGHT MINUTES
            switch (roundDirection) {
                case 'roundUp':
                    time = time + 100 - 60;
                    break;
                case 'roundDown':
                    time = time - 40;
                    break;
            }
        if (time > 2400)
            time = time - (2400 * Math.floor(time / 2400));
        return time;
    }

    getTimeZoneForEventObjectLabel(event: Appointment | ClassSession | WaitList): string {
        let objectLabel = '';
        if (event.location && event.location.timezone)
            objectLabel = this.configListService.getTitleCaseLabel('locationLabel')  + '\'s timezone';
        else if ((event instanceof Appointment || event instanceof ClassSession) && event.location && !event.location.timezone &&
            event.locationGroup && event.locationGroup.groupId && event.locationGroup.timeZoneCode)
            objectLabel = this.configListService.getTitleCaseLabel('locationGroupLabel')  + '\'s timezone';
        else if (event.location && !event.location.timezone &&
            (event instanceof WaitList || ((event instanceof Appointment || event instanceof ClassSession) && (!event.locationGroup || !event.locationGroup.groupId || !event.locationGroup.timeZoneCode)))
            && (event.staff && event.staff.timezone))
            objectLabel = this.configListService.getTitleCaseLabel('staffLabel')  + '\'s timezone';
        else
            objectLabel = 'Business\'s timezone';
        return objectLabel;
    }

    getTimeZoneLabel(event: Appointment | ClassSession | WaitList): string {
        let timeZone = this.businessTimezone;
        if (event.location && event.location.timezone) // IF LOCATION HAS TIMEZONE, SHOW THAT
            timeZone = event.location.timezone;
        else if ((event instanceof Appointment || event instanceof ClassSession) && event.location && !event.location.timezone &&
            event.locationGroup && event.locationGroup.groupId && event.locationGroup.timeZoneCode) // IF LOCATION DOESN'T HAVE TIMEZONE BUT LOCATION GROUP HAS TIMEZONE THEN SHOW THAT
            timeZone = event.locationGroup.timeZoneCode.timeZoneDisplayName;
        else if (event.location && !event.location.timezone &&
            (event instanceof WaitList || ((event instanceof Appointment || event instanceof ClassSession) && (!event.locationGroup || !event.locationGroup.groupId || !event.locationGroup.timeZoneCode)))
            && (event.staff && event.staff.timezone)) // IF LOCATION NOR LOCATION GROUP HAVE TIMEZONE BUT STAFF DOES, SHOW STAFF'S TIMEZONE
            timeZone = event.staff.timezone;
        else
            timeZone = this.businessTimezone;
        return timeZone;
    }

    getDisplayNameForTimezone(timezone: TimeZoneCode): string {
        let timeZoneDisplayName = timezone.timeZoneCode;
        if (timeZoneDisplayName && timeZoneDisplayName.indexOf('_') !== -1)
            timeZoneDisplayName = this.formatFnsService.removeUnderscoresInLabels(timeZoneDisplayName);
        return timeZoneDisplayName;
    }

    getTimezoneForEvent(event: Appointment | ClassSession | WaitList) {
        if (!event || !this.businessTimezone) {
            setTimeout(() => {
                this.getTimezoneForEvent(event);
            }, 500);
        } else {
            const objectLabel = this.getTimeZoneForEventObjectLabel(event);
            let timezoneLabel = this.getTimeZoneLabel(event);
            if (timezoneLabel && timezoneLabel.indexOf('_') !== -1)
                timezoneLabel = this.formatFnsService.removeUnderscoresInLabels(timezoneLabel);
            if (event instanceof Appointment)
                event.apptTZ = timezoneLabel;
            else if (event instanceof ClassSession)
                event.classTZ = timezoneLabel;
            else if (event instanceof WaitList)
                event.waitListTZ = timezoneLabel;
            return objectLabel + ' (' + timezoneLabel + ') ';
        }
    }

    configureTimeDisplay(timeZoneCode: string, time: SingleTimeSlot, startTimeProperty: string, endTimeProperty: string, preference: preference, reason: Reason, selectedDate: string | Date): string {
        const startTimeDateForTimeslotDisplay = this.getTimeFromUTCTimeInClientTimezone(time[startTimeProperty], timeZoneCode);
        const endTimeDateForTimeslotDisplay = this.getTimeFromUTCTimeInClientTimezone(time[endTimeProperty], timeZoneCode);
        const timeZoneForMoment = this.checkToAddMomentTimezone(0, timeZoneCode);
        const startDateInTimezone = momentTimezone(startTimeDateForTimeslotDisplay).tz(timeZoneForMoment).format('YYYY-MM-DD');
        const endDateInTimezone = momentTimezone(endTimeDateForTimeslotDisplay).tz(timeZoneForMoment).format('YYYY-MM-DD');

        let additionalStartFormat = '';
        let additionalEndFormat = '';
        if (selectedDate !== undefined && selectedDate !== startDateInTimezone)
            additionalStartFormat =  'l ';
        if (endDateInTimezone !== startDateInTimezone)
            additionalEndFormat = 'l ';
        let startFormat = 'HH:mm';
        let endFormat = 'HH:mm';
        if (!preference.classCalendar && reason.reasonType === 'CLASS') {
            startFormat = 'llll';
            if (endDateInTimezone !== startDateInTimezone)
                endFormat = 'llll';
            else
                endFormat = 'LT';
            if (preference.timeFormat24Hrs){
                startFormat = 'ddd, ll - HH:mm';
                if (endDateInTimezone !== startDateInTimezone) {
                    endFormat = 'ddd, ll - HH:mm';
                }
            }
        } else if (!preference.timeFormat24Hrs) {
            startFormat = additionalStartFormat + 'LT';
            endFormat = additionalEndFormat + 'LT';
        } else {
            startFormat = additionalStartFormat + startFormat;
            endFormat = additionalEndFormat + endFormat;
        }
        const startTimeDisplay = momentTimezone(startTimeDateForTimeslotDisplay).tz(timeZoneForMoment).format(startFormat);
        const endTimeDisplay = momentTimezone(endTimeDateForTimeslotDisplay).tz(timeZoneForMoment).format(endFormat);
        const timeStringDisplay = startTimeDisplay + ' - ' + endTimeDisplay;
        return timeStringDisplay;

    }

    datesEqual(date1: string | Date, date2: string | Date): boolean {
        const stringDate1 = this.getStringDateFromTime(date1);
        const stringDate2 = this.getStringDateFromTime(date2);
        return stringDate1 === stringDate2;
    }

    checkStartTimeBeforeEndTime(startDate: string | Date, endDate: string | Date, startTime: number, endTime: number, propertySetting: 'startTime' | 'endTime', isRepeating: boolean, minutes?: number): number {
        if (!minutes)
            minutes = 5;
        let timeToReturn: number;
        switch (propertySetting) {
            case 'endTime':
                timeToReturn = endTime;
                break;
            case 'startTime':
                timeToReturn = startTime;
        }
        const datesEqual = this.datesEqual(startDate, endDate);
        // console.log('*****START checkStartTimeBeforeEndTime******');
        // console.log('propertySetting ' + propertySetting);
        // console.log('startDate ' + JSON.stringify(startDate));
        // console.log('endDate ' + JSON.stringify(endDate));
        // console.log('startTime ' + startTime);
        // console.log('endTime ' + endTime);
        // console.log('dates equal ' + datesEqual);
        let minimumEndTime = this.calculateEndTime(minutes, startTime, 'roundUp'); // TT-7947 so that time is always at least the minimun amount for service
        if ((isRepeating && endTime <= startTime) || (!isRepeating && datesEqual && ((endTime <= startTime) || (endTime < minimumEndTime))))
            switch (propertySetting) {
                case 'endTime':
                    timeToReturn = this.calculateEndTime(minutes, startTime, 'roundUp');
                    break;
                case 'startTime':
                    timeToReturn = this.calculateEndTime(-1 * minutes, endTime, 'roundDown');
                    break;
            }
        // console.log('timeToReturn ' + timeToReturn);
        // console.log('*****END checkStartTimeBeforeEndTime******');
        return timeToReturn;
    }

    removeSelectedTimesFromTimeForm(timeForm: FormGroup, selectedTime: SelectedDateTimeObj,reasonType: ReasonTypes){
        for(let prop in timeForm.controls) {
            // @ts-ignore
            if(reasonType === ReasonTypes.CLASS && timeForm.controls[prop].controls['classScheduleId'].value === selectedTime.classScheduleId){
                timeForm.removeControl(prop);
                break;
            }

            // @ts-ignore
            if(reasonType === ReasonTypes.SERVICE && timeForm.controls[prop].controls['timeSlotId'].value === selectedTime.timeSlotId && timeForm.controls[prop].controls['clientTimeSlotId'].value === selectedTime.clientTimeSlotId && timeForm.controls[prop].controls['htmlId'].value === selectedTime.htmlId){
                timeForm.removeControl(prop);
                break;
            }

            // @ts-ignore
            if(reasonType === ReasonTypes.COURSE && timeForm.controls[prop].controls['recurringScheduleId'].value === selectedTime.recurringScheduleId){
                timeForm.removeControl(prop);
                break;
            }

        }
        let count = 0;
        for(let prop in timeForm.controls){
            let formGroup = timeForm.controls[prop];
            timeForm.removeControl(prop);
            if(count == 0){
                timeForm.addControl('selectedDateTime', formGroup);
            } else {
                let formControlName = 'selectedDateTime' + count;
                timeForm.addControl(formControlName, formGroup);

            }
            count++;
        }
    }

    getAppointmentIntentDateTimeDisplayPropertiesWithTimezone(intent: AppointmentIntent, timezonecode: string){
        //intent.startDate = this.getTimeFromUTCTimeInClientTimezone(new Date(intent.startDate), timezonecode);
        //intent.endDate = this.getTimeFromUTCTimeInClientTimezone(new Date(intent.endDate), timezonecode);
        //intent.startDateDisplay = momentTimezone(intent.startDate).tz(timezonecode).format('MM/DD/YYYY');
        //intent.endDateDisplay = momentTimezone(intent.endDate).tz(timezonecode).format('MM/DD/YYYY');

        return intent;
  }

}
