import {ConfigListMap, preference} from "../common-classes/app-objects.model";
import {TitleCasePipe} from "@angular/common";
import {OnInit} from "@angular/core";

export class SpanishLabelMap implements OnInit{
    errorLoadingLabel: string = "Error al cargar datos. Actualice la página e intente nuevamente.";
    additionalGuestText: string = "Invitados adicionales";
    allowSMSMessages: string = "Permitir mensajes SMS";
    allowEmailMessages: string = "Permitir mensajes de correo electrónico";
    appointmentDetailText: string = "<dl class=\"row\">\n" +
        "<dt class=\"col-3\"><strong>Estado:</strong></dt>\n" +
        "<dd class=\"col-9\">%APPT_STATUS_CHIP%</dd>\n" +
        "<dt class=\"col-3\"><strong>Id:</strong></dt>\n" +
        "<dd class=\"col-9\">%APPT_ID%</dd>\n" +
        "<dt class=\"col-3\"><strong>Fecha y hora:</strong></dt>\n" +
        "<dd class=\"col-9\">%APPT_DATE_TIME%</dd>\n" +
        "<dt class=\"col-3\"><strong> Servicio:</strong></dt>\n" +
        "<dd class=\"col-9\">%REASON%</dd>\n" +
        "<dt class=\"col-3\"><strong>Ubicación:</strong></dt>\n" +
        "<dd class=\"col-9\">%LOCATION_NAME%</dd>\n" +
        "<dt class=\"col-3\"><strong>Personal:</strong></dt>\n" +
        "<dd class=\"col-9\">%STAFF_FULLNAME%</dd>\n" +
        "</dl>";
    apptDetailTitle: string = "Detalle de la cita";
    apptDetailPendingApptInfoMsg: string = "Su cita está actualmente pendiente de confirmación. Se le notificará una vez que el personal haya aprobado su solicitud de cita.";
    errorModalCloseBtnLabel: string = "Cerrar";
    apptCancelHourPlural: string = this.cancelHrs==1 ? 'hora' : 'horas';
    apptCancelPastTimeframeMsg: string = "El plazo permitido para cancelar esta cita ha expirado.. Se permiten cancelaciones hasta %ALLOWED_CANCEL_HOURS% " + this.apptCancelHourPlural + " antes de la hora de inicio de la cita.";
    apptDoesNotBelongToAccountErrorMsg: string = "Se produjo un error al cargar los detalles de esta cita. Esta cita no pertenece a esta cuenta.";
    apptDoesNotBelongToAccountErrorTitle: string = "Error al cargar los detalles de la cita";
    apptMaxRescheduleCountErrorMsg: string = "Ha alcanzado el número máximo de reprogramaciones permitidas para esta cita.";
    apptListErrorLoadingMsg: string = "Error al cargar listas de citas. Inténtelo de nuevo más tarde";
    apptMaxRescheduleCountErrorTitle: string = "Reprogramación no permitida";
    apptRescheduleHourPlural: string = this.rescheduleHrs==1 ? 'hora' : 'horas';
    apptReschedulePastTimeframeMsg: string = "Ha pasado el plazo permitido para reprogramar esta cita. La reprogramación está permitida hasta %ALLOWED_RESCHEDULE_HOURS% " + this.apptRescheduleHourPlural + " antes de la hora de inicio de la cita.";
    apptRescheduleNotAllowedTitle: string = "Reprogramar cita";
    appointmentFieldList: string = null;
    authorizeCCBtnLabel: string = "Autorizar tarjeta";
    bookNewApptLabel: string = "Reservar una nueva cita";
    cancelApptErrorMsg: string = "Se produjo un error al cancelar su cita. Actualice la página e intente nuevamente";
    cancelApptNoReasonEnteredErrorMsg: string = "No se ingresó ninguna razón. Debe ingresar una razón para cancelar su cita";
    cancelBtnLabel: string = "Cancelar";
    cancelModalTitle: string = "Cancelar cita";
    cancelModalDescription: string = "No se ingresó una razón. Debe ingresar una razón para cancelar su cita";
    cancelModalReasonTitle: string = "Motivo de cancelacion:";
    cancelledApptBtnLabel: string = "Cancelada";
    cancelledApptTitle: string = "Citas canceladas";
    clientBlacklistError: string = "La dirección de correo electrónico que ha ingresado se ha incluido en nuestra lista de clientes bloqueados. En este momento, no puede crear una cita con nuestra oficina. Gracias";
    clientCancelTimeframeError: string = "Está fuera del plazo permitido para cancelar esta cita";
    clientConfirmedApptMsg: string = "¡Gracias! Su cita esta confirmada";
    clientProfileTitle: string = 'Perfil de %CLIENT_FULLNAME%';
    clientDetailsCancelEditBtnLabel: string = 'Cancelar ediciones';
    clientDetailsEditBtnLabel: string = 'Editar';
    clientDetailsSaveEditBtnLabel: string = 'Guardar cambios';
    completedApptBtnLabel: string = "Completada";
    completedApptTitle: string = "Citas completadas";
    courseSessionsLabel: string = "Sesiones";
    errorSavingAllAppointmentsDblBooked: string = "<div style='text-align: center;'> Desafortunadamente, todas las fechas y horas de las citas que seleccionó han sido reservadas recientemente por otra persona. Haga clic de nuevo para elegir fechas y horas diferentes.</div>";
    errorSavingAppointment: string = "<div style='text-align: center;'>Se produjo un error al guardar su cita. Actualice su página e intente nuevamente.</div>";
    errorSavingAppointmentDblBooked: string = "<div style='text-align: center;'> Desafortunadamente, la fecha y hora que ha seleccionado ha sido reservada recientemente por otra persona. Haga clic de nuevo para elegir una fecha y hora diferentes.</div>";
    errorSavingClassApptAlreadyEnrolled: string = "Ya está inscrito en esta sesión. No puede inscribirse en una sesión de clase";
    fileUploadBtnChooseFile: string = "Elegir archivo";
    fileUploadBtnUploadingFile: string = "Subir archivos";
    fileUploadBtnUploadedFile: string = "¡Archivo ha sido subido!";
    fileUploadErrorMsg: string = "Error al cargar el archivo Inténtalo de nuevo.";
    filterButton: string = "Buscar";
    filterLabel: string = "Filtrar por código postal";
    genericErrorMsg: string = "Error al cargar datos";
    headerNavLogin: string = 'Iniciar sesión';
    headerNavMyAccount: string = 'Mi cuenta';
    headerNavRegister: string = 'Registrarse';
    headerNavScheduler: string = 'Reservar cita';
    headerNavStore: string = 'Paquetes';
    hourLabel: string = 'Hora';
    hoursLabel: string = 'Horas';
    infoPnlBookingSummary: string = "Programando para %REASON% el %APPT_CLIENT_START_DATE_TIME_MEDIUM%";
    infoBookingSummaryWaitList: string = "Lista de espera para %REASON% el %APPT_CLIENT_START_DATE_TIME_MEDIUM%";
    infoPnlNameLabel: string = "Reservando para:";
    infoPnlSendTextCBLabel: string = 'Enviar recordatorio por mensaje de texto:';
    infoPnlSendTextHrsCBLabel: string = "Elija cuándo desea recibir el texto";
    infoPnlSendTextD1Hr: string = '1 hora por adelantado';
    infoPnlSendTextD2Hr: string = '2 horas por adelantado';
    infoPnlSendTextD4Hr: string = '4 horas por adelantado';
    infoPnlSendTextD12Hr: string = '12 horas por adelantado';
    infoPnlSendTextD24Hr: string = '24 horas por adelantado';
    infoTitle: string = "Su información";
    invitationCampaignErrorNoInvitationTitle: string = "Necesita invitación";
    invitationCampaignErrorNoInvitationMsg: string = "Este programador de citas requiere una invitación. Solicite una invitación al negocio o empresa.";
    invitationCampaignErrorInvitationCompletedTitle: string = "\"Invitación ya ha sido completada";
    invitationCampaignErrorInvitationCompletedMsg: string = "Ya ha reservado a través de esta invitación. Solicite otra invitación de la empresa para reservar de nuevo.";
    invitationCampaignErrorInvitationExpiredTitle: string = "Invitación expirada";
    invitationCampaignErrorInvitationExpiredMsg: string = "Su invitación para programar una cita con este negocio o empresa ha expirado";
    invoiceConnectingWithPaymentPortal: string = "Conectando al portal de pagos ...";
    invoiceCreditCardChargeLabel: string = "Pagar %INVOICE_PAYMENT_AMOUNT%";
    invoiceCreditCardChargeFailedLabel: string = "No pudimos procesar su pago en este momento, por favor inténtelo nuevamente. Contactenos si el problema persiste.";
    invoiceCreditCardChargingLabel: string = "Enviando pago...";

    invoiceCreditCardAuthorizeLabel: string = "Autorizar por %INVOICE_PAYMENT_AMOUNT%";
    invoiceCreditCardAuthorizeFailedLabel: string = "No pudimos autorizar su tarjeta en este momento. Por favor intente nuevamente. Contactenos si el problema persiste. ";
    invoiceCreditCardAuthorizingLabel: string = "Autorizando tarjeta...";

    invoiceDateIssuedLabel: string = "Fecha de emisión:";
    invoiceDetailTitle: string = "Detalles de la factura";
    invoiceIdLabel: string = "Id de factura:";
    invoiceItemsTableHeader: string = "Datos de la factura:";
    invoiceDownloadBtnLabel: string = "Descargar";
    invoiceListErrorLoadingMsg: string = "Error al cargar las listas de facturas. Vuelva a intentarlo más tarde";
    invoicePayBtnLabel: string = "Pagar ahora";
    invoiceQuoteDetailTitle: string = "Detalles de estimado";
    invoiceSubTotalLabel: string = "Total parcial:";
    invoiceEnterPaymentTitle: string = "Ingrese la información de la tarjeta";
    invoiceTotalLabel: string = "Factura Total:";
    invoicePaidToDateLabel: string = "Pagado hasta la fecha:";
    invoiceBalanceLabel: string = "Balance:";
    invoiceStatusLabel: string = "Estado:";
    invoicesOpenListTitle: string = "Abrir facturas";
    invoicesOpenBtnLabel: string = "Abiertas";
    invoicesNoOpenMsg: string = "No se encontraron facturas abiertas";
    invoicesClosedListTitle: string = "Facturas cerradas";
    invoicesClosedBtnLabel: string = "Cerrada";
    invoicesNoClosedMsg: string = "No se encontraron facturas cerradas";
    invoicesVoidedListTitle: string = "Facturas anuladas";
    invoicesVoidedBtnLabel: string = "Anulada";
    invoicesNoVoidedMsg: string = "No se encontraron facturas anuladas";
    invoicesViewDetailBtnLabel: string = "<i class ='fas fa-search'> </i>";
    labelBack: string = "Regresar";
    labelBookAnother: string = "Reservar otra cita";
    labelNext: string = "Próximo";
    labelNoPreference: string = "* Sin preferencias *";
    labelSave: string = "Guardar";
    locationGroupTitle: string = "Grupos de ubicación";
    locationGroupPnlErrorLoading: string = this.errorLoadingLabel;
    locationGroupPnlNoObjectsMsg: string = "No hay grupos de locaciones disponibles para reservar en este momento";
    locationSuperGroupTitle: string = "Ubicación Super Grupos";
    locationSuperGroupPnlErrorLoading: string = this.errorLoadingLabel;
    locationSuperGroupPnlNoObjectsMsg: string = "No hay supergrupos de locaciones disponibles para reservar en este momento";
    locationPnlErrorLoading: string = this.errorLoadingLabel;
    locationPnlNoLocationsFilterSearchMsg: string = "No se encontraron locaciones con la búsqueda '%FILTER_SEARCH%'";
    locationPnlMapInfoWindowContent: string = "<strong>%LOCATION_NAME%</strong><br/> %LOCATION_ADDRESS%";
    locationPnlVariableAddressInitialDescription: string = "Escriba la dirección de su cita a continuación. Una vez que haya terminado, presione el botón de validación para completar la búsqueda.";
    locationPnlVariableAddressInputLabel: string = "Dirección";
    locationPnlVariableAddressMatchingAddressesTitle: string = "Direcciones coincidentes:";
    locationPnlVariableAddressMatchingAddressesDescription: string = "A continuación se muestra una lista de direcciones que coinciden con la dirección que escribió. Haga clic en el botón de confirmación junto a la dirección que mejor representa la suya:";
    locationPnlVariableAddressTitle: string = "Introducir dirección";
    locationPnlVariableAddressValidateBtn: string = "Validar dirección";
    locationPnlVariableAddressConfirmBtn: string = "Confirmar dirección";
    locationTitle: string = "Ubicación";
    locationNoAvailError: string = "No hay locaciones disponibles para reservar en este momento";
    loginErrorMsg: string = "Hubo un error al iniciar sesión. Verifique su dirección de correo electrónico y contraseña e intente nuevamente.";
    loginTitle: string = "Iniciar sesión";
    loginForgotPwd: string = "¿Olvidó su contraseña?";
    loginBtnLabel: string = "Iniciar sesión";
    loginBtnLabelLoggingIn: string = "Iniciando sesión...";
    loggedInWelcomeText: string = "<div style = 'text-align: center; font-size: large;'> <p> Ahora que ha iniciado sesión en nuestro programador, puede: </p> <p style ='margin-bottom: 5px;'> & bull; Programe una nueva cita </p> <p> & bull; Ver y administrar citas pasadas y futuras </p> <p> Seleccione un botón a continuación para comenzar: </p> </div>";
    loggedInWelcomeTitle: string = '¡Bienvenido,%CLIENT_FIRSTNAME%!';
    logoutLabel: string = "Cerrar sesión";
    maxApptsReachedModalErrorMsg: string = "Solo se le permite tener %MAX_ACTIVE_APPTS% citas abiertas a la vez y ha alcanzado ese límite. Primero debe cancelar una cita existente antes de poder reservar nuevas citas.";
    maxApptsReachedModalErrorTitle: string = "Número máxima de citas se ha alcanzado";
    maxApptsReachedBookingErrorMsg: string = "Ha seleccionado reservar para %CLIENT_FULLNAME% pero ya ha alcanzado el número máximo de citas permitidas. " + this.maxApptsReachedModalErrorMsg;
    maxApptsReachedBookingMultipleErrorMsg: string = "Este programador solo permite programar hasta %MAX_ACTIVE_APPTS% citas en cualquier momento. Ha seleccionado reservar %TIME_CART_COUNT% citas para %CLIENT_FULLNAME%. %CLIENT_FULLNAME% ya tiene %CLIENT_ACTIVE_APPT_COUNT% citas activas y reservar %TIME_CART_COUNT% los podría por encima del máximo permitido de %MAX_ACTIVE_APPTS%. Vuelva a la página de horarios disponobles y remueva %APPT_COUNT_TO_REMOVE% citas de su carrito de citas.";
    minuteLabel: string = 'minuto';
    minutesLabel: string = 'minutos';
    myAccountMyProfileBtnLabel: string = "Mi perfil";
    noAppointmentText: string = "Todos los horarios de citas están reservados actualmente. Vuelva a consultar más tarde";
    noStaffError: string = "Actualmente no hay disponibilidad para que pueda hacer una cita a través de este programador. Vuelva a consultar más tarde";
    noCancelledApptsMsg: string = "No se encontraron citas canceladas";
    noCompletedApptsMsg: string = "No se encontraron citas completadas";
    noNoShowApptsMsg: string = "No se encontraron citas ausente";
    noOpenApptsMsg: string = "No se encontraron citas abiertas";
    noshowApptBtnLabel: string = "Ausente";
    noshowApptTitle: string = "Citas Ausentes";
    openApptTitle: string = "Citas Abiertas";
    passwordFieldLabel: string = "Establezca su contraseña";
    payNowButtonLabel: string = 'Pagar ahora';
    poweredByTimeTap: string = "Desarrollado por <a href='https://www.timetap.com' target='_blank'> TimeTap.com";
    quotaFullMsg: string = "El motivo seleccionado para su cita ha alcanzado su cuota máxima para el período de reserva";
    reasonPnlNoReasonsMsg: string = "No hay servicios o clases disponibles para reservar en este momento";
    repeatingApptDateList: string = "<li> <b>%APPT_DATE_TIME%: </b>%STAFF_FULLNAME% en %LOCATION_NAME% </li>";
    registerDescription: string = "Primero, ingrese su correo electrónico y verificaremos si ha hecho citas aquí en el pasado. Si es así, le enviaremos un correo electrónico de restablecimiento de contraseña";
    registerDescriptionClientExists: string = "¡Parece que has estado aquí antes! Te hemos enviado un correo electrónico para establecer una contraseña para el programador. Una vez que hayas hecho eso, vuelve aquí para iniciar sesión.";
    registerTitle: string = "Registrarse";
    registerButtonCheckEmail: string = "Verificar correo electrónico";
    registerDescriptionNoClientFound: string = "No pudimos encontrar su dirección de correo electrónico %CLIENT_EMAILADDRESS% en nuestros registros. Complete los campos a continuación para registrarse en nuestro programador:";
    registerErrorMsg: string = "Hubo un error al enviar su registro. Inténtelo de nuevo";
    registerButtonRegister: string = "Registrarse";
    registerLoginErrorMsg: string = "Te registramos correctamente para el programador, pero hubo un error al iniciar sesión. Actualiza la página y haz clic en el botón de inicio de sesión para volver a intentarlo.";
    requiredFieldsEmptyMsg: string = "Por favor complete todos los campos requeridos. Desplácese hacia abajo si es necesario.";
    resetPwdEmailSent: string = "Hemos enviado un correo electrónico con un enlace para restablecer su contraseña a %CLIENT_EMAILADDRESS%. Revise su correo electrónico, restablezca su contraseña y luego regrese aquí para iniciar sesión.";
    resetPwdTitle: string = "Restablecer contraseña";
    resetPwdButtonLabel: string = "Restablecer contraseña";
    resetPwdDescription: string = "Ingrese su dirección de correo electrónico a continuación y haga clic en el botón " + this.resetPwdButtonLabel + " a continuación.";
    rescheduleBtnLabel: string = "Reprogramar";
    serviceGroupTitle: string = "Grupo de razones";
    serviceGroupPnlErrorLoading: string = this.errorLoadingLabel;
    serviceGroupPnlNoObjectsMsg: string = "No hay super groups de razones disponibles para reservar en este momento";
    serviceSuperGroupTitle: string = "Super Grupo de Razones";
    serviceSuperGroupPnlErrorLoading: string = this.errorLoadingLabel;
    serviceSuperGroupPnlNoObjectsMsg: string = "No hay motivos para que los supergrupos estén disponibles para reservar en este momento";
    serviceTitle: string = "Servicio";
    servicePnlCouponButton: string = "Aplicar";
    servicePnlCouponErrorNoCode: string = "No se ingresó ningún código de cupón. Ingrese un código y vuelva a intentarlo";
    servicePnlCouponErrorNoCouponFound: string = "No se encontró ningún cupón con el código: '%COUPON_CODE%'. Revise el código y vuelva a intentarlo.";
    servicePnlCouponErrorExpired: string = "El código de cupón '% COUPON_CODE%' expiró el %COUPON_ENDDATE%.";
    servicePnlCouponErrorMaxUseReached: string = "El código de cupón '%COUPON_CODE%' ya ha alcanzado su recuento de uso máximo de %COUPON_MAX_USE_COUNT%.";
    servicePnlCouponErrorNoReasonsDiscounted: string = "El código de cupón '%COUPON_CODE%' no se aplica a ninguno de los motivos de citas mostrados.";
    servicePnlCouponSuccess: string = "El código de cupón '%COUPON_CODE%' se ha aplicado a %COUPON_APPLIED_REASON_COUNT% razones a continuación.";
    servicePnlCouponLabel: string = "Ingresar código de cupón";
    servicePnlErrorLoading: string = this.errorLoadingLabel;
    servicePnlNoObjectsMsg: string = "No hay servicios o clases disponibles para reservar en este momento";
    staffGroupTitle: string = "Grupo de personal";
    staffTitle: string = "Personal";
    staffPnlErrorLoading: string = this.errorLoadingLabel;
    staffPnlNoObjectsMsg: string = "No hay personal disponible para reservar en este momento";
    staffPnlNoStaffFilterSearchMsg: string = "No se encontró personal con la búsqueda '%FILTER_SEARCH%'";
    switchToRegisterLink: string = "Regístrese ahora";
    switchToRegisterText: string = "¿Necesita una cuenta?";
    thanksCourses: string = "<p style='text-align: center;'> <strong> Su registro para %REASON% es exitoso </strong> <br/> <br /> Personal: %STAFF_FULLNAME% <br /> Conjunto: %SET_NAME% <br /> <br /> Ubicación: %LOCATION_NAME% <br />% LOCATION_ADDRESS% </p> ";
    thanksInfo: string = "<p style='text-align: center;'> <strong> ¡Su cita ha sido programada! </strong> <br> <br> Personal: %STAFF_FULLNAME% <br> Hora: %APPT_DATE_TIME% <br>Servicio de: %REASON% <br> <br> Ubicación: %LOCATION_NAME% <br>%LOCATION_ADDRESS% <br> </p> ";
    thanksInfoRepeating: string = "<p style='text-align: center;'> <strong> ¡Se han programado sus citas para %REASON%! </strong> </p> <div style='text-align: center' >%REPEATING_APPT_DATE_LIST% </div> ";
    thanksInfoRepeatingApptsNotSaved: string = "<p style='text-align: center'> <strong>  Lamentablemente, estos horarios fueron reservados por otra persona y no pudieron ser reservados:  </strong> </p> <br> <div style='text-align: center;'>%REPEATING_APPT_DATE_LIST% </div> ";
    thanksPnlErrorLoading: string = this.errorLoadingLabel;
    thanksInfoConfirm: string = "Su cita está esperando la confirmación del personal. En breve recibirá un correo electrónico para informarle si el personal ha confirmado o rechazado su solicitud de cita.";
    thanksInfoConfirmRepeating: string = "Sus citas están esperando la confirmación del personal. Recibirá un correo electrónico en breve para informarle si el personal ha confirmado o rechazado sus solicitudes de cita.";
    thanksConfirmPnlErrorLoading: string = this.errorLoadingLabel;
    thanksInfoPayment: string = "<p style='text-align: center;'> <strong> Su cita está programada. Puede enviar el pago ahora o espere hasta la hora de su cita. </strong> <br /> <br/>  Si desea continuar y enviar el pago, haga clic en el botón a continuación:  <br/>%PAYMENT_BUTTON% </p> ";
    thanksInfoPaymentRepeating: string = "<p style='text-align: center;'> <strong>  Su citas está programada. Puede enviar el pago ahora o espere hasta su primera cita. </strong> <br /> <br/> Puede enviar el pago ahora o espere hasta la hora de su cita: <br />%PAYMENT_BUTTON% </p> ";
    thanksInfoPaymentAuthOnly: string = "<p style='text-align: center;'> <strong> Ingrese los detalles de su tarjeta de crédito con el botón a continuación para confirmar su cita. </strong> <br /> <br /> Al hacer clic en el botón de pago a continuación, se le pedirá que ingrese la información de su tarjeta de crédito, pero no se retirará dinero de su tarjeta en este momento. Esto solo es necesario para celebrar su cita. <br />%PAYMENT_BUTTON% </p> ";
    thanksInfoPaymentAuthOnlyRepeating: string = "<p style='text-align: center;'> <strong> Ingrese los detalles de su tarjeta de crédito usando el botón a continuación para confirmar sus citas. </strong> <br /> <br /> Al hacer clic en el botón de pago a continuación, se le pedirá que ingrese la información de su tarjeta de crédito, pero no se extraerá dinero de su tarjeta en este momento. Esto solo es necesario para mantener sus citas. <br />%PAYMENT_BUTTON% </p> ";
    thanksInfoPaymentRequired: string = "<p style='text-align: center;'> <strong> Su cita aún no está programada. </strong> <br /> <br /> Haga clic en el botón a continuación para realizar el pago. De lo contrario, se cancelará su cita. <br />% PAYMENT_BUTTON% </p> ";
    thanksInfoPaymentRequiredRepeating: string = "<p style='text-align: center;'> <strong> Sus citas aún no están programadas. </strong> <br /> <br /> Haga clic en el botón a continuación para realizar el pago sus citas. De lo contrario, se cancelarán sus citas. <br />% PAYMENT_BUTTON% </p> ";
    thanksPaymentTitle: string = "Pagar ahora o más tarde";
    thanksPaymentTitleAuthOnly: string = "Autorice su tarjeta";
    thanksPaymentTitleRequired: string = "Pagar ahora";
    thanksTitle: string = "¡Gracias!";
    thanksWaitListTitle: string = "Estás en la lista de espera";
    timePnlBookingSummary: string = "Programando para %REASON% con %STAFF_FULLNAME% en %LOCATION_NAME%";
    timePnlCalendarLabel: string = "Seleccionar fecha:";
    timePnlCartBookingTimesListTitle: string = "Fechas y horarios seleccionados para la cita:";
    timePnlCartCloseBtnLabel: string = "Cerrar";
    timePnlCartDescription: string = "A continuación se muestran todas los horarios que ha seleccionado. Si ya no desea reservar una de las horas seleccionadas, haga clic en el botón para eliminarla.";
    timePnlCartNoTimesSelected: string = "Actualmente no hay horarios seleccionados. Cierre esta ventana y seleccione algunas fechas y horas del calendario para reservar";
    timePnlCartRemoveBtnLabel: string = "Remover";
    timePnlErrorLoading: string = this.errorLoadingLabel;
    timePnlCartTitle: string = "Horarios seleccionados";
    timePnlCartWaitlistTimesListTitle: string = "Fechas y horarios seleccionadas para la lista de espera:";
    timePnlChangeTimezoneLabel: string = "Cambiar zona horaria";
    timePnlLoadingTimesLabel: string = "Cargando horarios disponibles...";
    timePnlNoTimesAvailOnSelectDay: string = "No hay horarios disponibles en la fecha seleccionada. Elija una fecha diferente";
    timePnlNoTimesAvailOnSelectMonth: string = "No hay horarios disponibles en el mes actual. Elija un mes diferente";
    timePnlRescheduleBookingSummary: string = "Reprogramación para %REASON% con %STAFF_FULLNAME% en %LOCATION_NAME%";
    timePnlSeatsLabel: string = "Asientos restantes";
    timePnlSeatsFullJoinWaitlist: string = "0 espacios restantes. Únase a la lista de espera.";
    timePnlSelectCourseSetLabel: string = "Seleccione el conjunto de cursos que desea reservar:";
    timePnlSelectTimeLabel: string = "Seleccione horario el:";
    timePnlSelectTimeNoClassCalendarLabel: string = "Seleccionar fecha y horario de sesión:";
    timePnlSelectedTimesBtnLabel: string = "%SELECTED_TIMES_COUNT% veces seleccionadas";
    timePnlTimezoneLabel: string = "Su zona horaria";
    timePnlTimezoneLabelForBusiness: string = "Zona horaria:";
    timePnlWaitlistTitle: string = "Únase a la lista de espera";
    timePnlWaitlistText: string = this.waitListFirstAvailable ? "<div style='text-align: center;'>Si desea ser notificado en especificos días y horarios disponibles, seleccione los días y horarios en el calendario. De lo contrario, puede hacer clic en el botón para recibir una notificación de la primera apertura disponible:</div>" : "<div style='text-align: center;'>Seleccione la(s) fecha(s) y horario(s) para las cuales le gustaría estar en la lista de espera y le enviaremos una notificación si esos horarios están disponibles.</div>";
    timeTitle: string = "Horario";
    upcomingApptBtnLabel: string = "Subiendo";
    viewApptDetailBtnLabel: string;
    viewApptLabel: string = "Ver citas";
    viewApptTitle: string = "Historial de citas";
    viewingAccountForLabel: string = "Viendo cuenta para:";
    viewingApptsForLabel: string = "Viendo citas para:";
    viewingInvoicesForLabel: string = "Viendo facturas para:";
    viewingProfileForLabel: string = "Viendo perfil para:";
    viewInvoiceLabel: string = "Ver facturas";
    waitListAcceptScreenErrorTitle: string = "Error al encontrar la cita";
    waitListAcceptScreenErrorText: string = "No pudimos encontrar la cita solicitada en la lista de espera. Asegúrese de tener el enlace adecuado e intente nuevamente. Si continúa teniendo problemas, comuníquese con el Soporte de TimeTap.";
    waitListAcceptScreenHeader: string = "Solicitar cita";
    waitListAcceptScreenText: string = "Si desea reclamar la cita con %STAFF_FULLNAME% en %LOCATION_NAME% para %REASON% en %APPT_DATE_TIME%, haga clic en el botón de reclamo.";
    waitlistAlreadyClaimed: string = "Cita ya reclamada";
    waitlistAlreadyClaimedText: string = "<p style='text-align: center;'>Lo sentimos, pero esta cita ya ha sido reclamada. Le haremos saber si hay otro horario disponible.</p>";
    waitlistClaimApptBtn: string = "Reclamar cita";
    waitlistClaimedApptCancelledTitle: string = "Cita cancelada";
    waitlistClaimedApptCancelledText: string = "Esta cita con %STAFF_FULLNAME% en %LOCATION_NAME% para %REASON% en %APPT_DATE_TIME% ha sido cancelado..";
    waitListDateListClass: string = "<li> <b>%APPT_DATE% a las %APPT_CLIENT_START_TIME_SHORT%: </b>%STAFF_FULLNAME% en %LOCATION_NAME% para %REASON% </li>";
    waitListDateListFirstAvail: string = "<li> <b> Primera fecha y hora disponibles: </b>%STAFF_FULLNAME% en %LOCATION_NAME% para %REASON% </li>";
    waitListDateListService: string = "<li> <b>%APPT_DATE% a las %APPT_CLIENT_START_TIME_SHORT%: </b>%STAFF_FULLNAME% en %LOCATION_NAME% para %REASON%</li>";
    waitListFirstAvailableInTimeCart: string = "Primera fecha y horario disponibles";
    waitListFirstAvailableText: string = "Haga clic aquí para recibir una notificación sobre la primera apertura disponible";
    waitListRegisterSuccessTitle: string = "¡Gracias!";
    waitlistRemoveError: string = "Se produjo un error al eliminarlo de la lista de espera. Inténtalo de nuevo. Si continúa teniendo problemas, póngase en contacto con el servicio de asistencia.";
    waitlistRemoveSuccessTitle: string = "Eliminado exitosamente";
    waitlistRemoveSuccessText: string = "<p style='text-align: center;'><strong>Ha sido eliminado exitosamente de la lista de espera para los siguientes registros: </strong></p><div style='text-align: center'>%WAITLIST_DATE_LIST%</div><p style='text-align: center;'></p>";
    waitlistRemoveTitle: string = "Eliminar de la lista de espera";
    waitlistRemoveText: string = "<p style='text-align: center;'><strong>Haga clic en el botón Eliminar a continuación si ya no desea recibir notificaciones de la lista de espera para los siguientes registros: </strong></p><div style='text-align: center'>%WAITLIST_DATE_LIST%</div><p style='text-align: center;'></p>";
    waitlistRemoveBtn: string = "Eliminar";
    waitListSuccessText: string = "<p style='text-align: center;'> <strong> Ha sido agregado a la lista de espera para las siguientes fechas y horarios: </strong> </p> <div style='text-align: center'>%WAITLIST_DATE_LIST% </div> <p style='text-align: center;'> En caso de que haya una cita disponible, será contactado por correo electrónico o por sms.</p> ";
    waitListText: string = "Haga clic aquí para unirse a la lista de espera para otra fecha/horario";
    waitListTextExitWaitListMode: string = "Elija entre fechas y horarios disponibles";
    welcomeText: string = "<p> <span style='font-size: x-large;'> Haga clic en las siguientes pantallas para seleccionar un intervalo de tiempo apropiado para su cita. </span> </p> <p> <span style='font-size: x-large;'> <br /> Una vez establecida su cita, recibirá un correo electrónico de confirmación. También recibirá un correo electrónico recordatorio 24 horas antes de su cita. <br/> ¡Gracias! <br/> </span> </p> ";
    welcomeTitle: string = "Bienvenido";
    welcomePnlErrorLoading: string = "El programador no se pudo cargar. Actualice su página e intente nuevamente.";
    cancelReasonList: string = null;
    filterHint: string = null;
    infoText: string = null;
    locationGroupText: string = null;
    locationSuperGroupText: string = null;
    locationText: string = null;
    serviceGroupText: string = null;
    servicePnlCouponHint: string = null;
    serviceSuperGroupText: string = null;
    serviceText: string = null;
    smsHint: string = null;
    staffGroupText: string = null;
    staffText: string = null;
    timeText: string = null;

    constructor(private configListLabels: ConfigListMap,
                private titleCasePipe: TitleCasePipe,
                private cancelHrs: number,
                private rescheduleHrs: number,
                private waitListFirstAvailable: boolean) {}

    ngOnInit(): void {
        if(this.rescheduleHrs === 0){
            this.apptReschedulePastTimeframeMsg = "Ha pasado el plazo permitido para reprogramar esta cita. La reprogramación está permitida hasta la hora de inicio de la cita.";
        }
        if(this.cancelHrs === 0){
            this.apptCancelPastTimeframeMsg = "El plazo permitido para cancelar esta cita ha expirado. Se permiten cancelaciones hasta la hora de inicio de la cita.";
        }
        //@ts-ignore

    }
}
