import {ConfigListMap, preference} from "../common-classes/app-objects.model";
import {TitleCasePipe} from "@angular/common";
import {OnInit} from "@angular/core";

export class FrenchLabelMap implements OnInit {
    errorLoadingLabel: string = "Erreur lors du chargement des données. Actualisez la page et réessayez.";
    additionalGuestText: string = "Invités supplémentaires";
    allowSMSMessages: string = "Autoriser les messages SMS";
    allowEmailMessages: string = "Autoriser les messages électroniques";
    appointmentDetailText: string = "<dl class=\"row\">\n" +
        "<dt class=\"col-3\"><strong>Statut :</strong></dt>\n" +
        "<dd class=\"col-9\">%APPT_STATUS_CHIP%</dd>\n" +
        "<dt class=\"col-3\"><strong>Id :</strong></dt>\n" +
        "<dd class=\"col-9\">%APPT_ID%</dd>\n" +
        "<dt class=\"col-3\"><strong>Date et heure :</strong></dt>\n" +
        "<dd class=\"col-9\">%APPT_DATE_TIME%</dd>\n" +
        "<dt class=\"col-3\"><strong>Service :</strong></dt>\n" +
        "<dd class=\"col-9\">%REASON%</dd>\n" +
        "<dt class=\"col-3\"><strong>Emplacement :</strong></dt>\n" +
        "<dd class=\"col-9\">%LOCATION_NAME%</dd>\n" +
        "<dt class=\"col-3\"><strong>Personnel :</strong></dt>\n" +
        "<dd class=\"col-9\">%STAFF_FULLNAME%</dd>\n" +
        "</dl>";
    apptDetailTitle: string = "Détails du rendez-vous";
    apptDetailPendingApptInfoMsg: string = "Votre rendez-vous est actuellement en attente de confirmation. Vous serez notifié une fois que le personnel aura approuvé votre demande de rendez-vous.";
    errorModalCloseBtnLabel: string = "Fermer";
    apptCancelHourPlural: string = this.cancelHrs==1 ? 'heure' : 'heures';
    apptCancelPastTimeframeMsg: string = "Le délai autorisé pour annuler ce rendez-vous a expiré. Les annulations sont autorisées jusqu'à %ALLOWED_CANCEL_HOURS% " + this.apptCancelHourPlural + " avant l'heure de début du rendez-vous.";
    apptDoesNotBelongToAccountErrorMsg: string = "Une erreur s'est produite lors du chargement des détails de ce rendez-vous. Ce rendez-vous n'appartient pas à ce compte.";
    apptDoesNotBelongToAccountErrorTitle: string = "Erreur de chargement des détails du rendez-vous";
    apptMaxRescheduleCountErrorMsg: string = "Vous avez atteint le nombre maximum de reports autorisés pour ce rendez-vous.";
    apptListErrorLoadingMsg: string = "Erreur lors du chargement des listes de rendez-vous. Veuillez réessayer plus tard";
    apptMaxRescheduleCountErrorTitle: string = "Report non autorisé";
    apptRescheduleHourPlural: string = this.rescheduleHrs==1 ? 'heure' : 'heures';
    apptReschedulePastTimeframeMsg: string = "Le délai autorisé pour reporter ce rendez-vous a expiré. Le report est autorisé jusqu'à %ALLOWED_RESCHEDULE_HOURS% " + this.apptRescheduleHourPlural + " avant l'heure de début du rendez-vous.";
    apptRescheduleNotAllowedTitle: string = "Reporter un rendez-vous";
    appointmentFieldList: string = null;
    authorizeCCBtnLabel: string = "Autoriser la carte";
    bookNewApptLabel: string = "Réserver un nouveau rendez-vous";
    cancelApptErrorMsg: string = "Une erreur s'est produite lors de l'annulation de votre rendez-vous. Actualisez la page et réessayez";
    cancelApptNoReasonEnteredErrorMsg: string = "Aucune raison n'a été saisie. Vous devez saisir une raison pour annuler votre rendez-vous";
    cancelBtnLabel: string = "Annuler";
    cancelModalTitle: string = "Annuler le rendez-vous";
    cancelModalDescription: string = "Aucune raison n'a été saisie. Vous devez saisir une raison pour annuler votre rendez-vous";
    cancelModalReasonTitle: string = "Raison de l'annulation :";
    cancelledApptBtnLabel: string = "Annulé";
    cancelledApptTitle: string = "Rendez-vous annulés";
    clientBlacklistError: string = "L'adresse e-mail que vous avez saisie a été ajoutée à notre liste de clients bloqués. Vous ne pouvez pas actuellement prendre rendez-vous dans notre bureau. Merci";
    clientCancelTimeframeError: string = "Vous êtes en dehors du délai autorisé pour annuler ce rendez-vous";
    clientConfirmedApptMsg: string = "Merci ! Votre rendez-vous est confirmé";
    clientProfileTitle: string = 'Profil de %CLIENT_FULLNAME%';
    clientDetailsCancelEditBtnLabel: string = 'Annuler les modifications';
    clientDetailsEditBtnLabel: string = 'Modifier';
    clientDetailsSaveEditBtnLabel: string = 'Enregistrer les modifications';
    completedApptBtnLabel: string = "Terminé";
    completedApptTitle: string = "Rendez-vous terminés";
    courseSessionsLabel: string = "Sessions";
    errorSavingAllAppointmentsDblBooked: string = "<div style='text-align: center;'> Malheureusement, toutes les dates et heures de rendez-vous que vous avez sélectionnées ont été récemment réservées par quelqu'un d'autre. Cliquez à nouveau pour choisir des dates et des heures différentes.</div>";
    errorSavingAppointment: string = "<div style='text-align: center;'>Une erreur s'est produite lors de l'enregistrement de votre rendez-vous. Actualisez votre page et réessayez.</div>";
    errorSavingAppointmentDblBooked: string = "<div style='text-align: center;'> Malheureusement, la date et l'heure que vous avez sélectionnées ont été récemment réservées par quelqu'un d'autre. Cliquez à nouveau pour choisir une date et une heure différentes.</div>";
    errorSavingClassApptAlreadyEnrolled: string = "Vous êtes déjà inscrit à cette session. Vous ne pouvez pas vous inscrire à une session de cours";
    fileUploadBtnChooseFile: string = "Choisir un fichier";
    fileUploadBtnUploadingFile: string = "Téléverser des fichiers";
    fileUploadBtnUploadedFile: string = "Le fichier a été téléversé !";
    fileUploadErrorMsg: string = "Erreur de téléversement du fichier. Réessayez.";
    filterButton: string = "Rechercher";
    filterLabel: string = "Filtrer par code postal";
    genericErrorMsg: string = "Erreur de chargement des données";
    headerNavLogin: string = 'Connexion';
    headerNavMyAccount: string = 'Mon compte';
    headerNavRegister: string = 'S\'inscrire';
    headerNavScheduler: string = 'Réserver un rendez-vous';
    headerNavStore: string = 'Forfaits';
    hourLabel: string = 'Heure';
    hoursLabel: string = 'Heures';
    infoPnlBookingSummary: string = "Réservation pour %REASON% le %APPT_CLIENT_START_DATE_TIME_MEDIUM%";
    infoBookingSummaryWaitList: string = "Liste d'attente pour %REASON% le %APPT_CLIENT_START_DATE_TIME_MEDIUM%";
    infoPnlNameLabel: string = "Réservation pour :";
    infoPnlSendTextCBLabel: string = 'Envoyer un rappel par SMS :';
    infoPnlSendTextHrsCBLabel: string = "Choisissez quand vous souhaitez recevoir le SMS";
    infoPnlSendTextD1Hr: string = '1 heure à l\'avance';
    infoPnlSendTextD2Hr: string = '2 heures à l\'avance';
    infoPnlSendTextD4Hr: string = '4 heures à l\'avance';
    infoPnlSendTextD12Hr: string = '12 heures à l\'avance';
    infoPnlSendTextD24Hr: string = '24 heures à l\'avance';
    infoTitle: string = "Vos informations";
    invitationCampaignErrorNoInvitationTitle: string = "Invitation requise";
    invitationCampaignErrorNoInvitationMsg: string = "Ce planificateur de rendez-vous nécessite une invitation. Demandez une invitation à l'entreprise ou à l'établissement.";
    invitationCampaignErrorInvitationCompletedTitle: string = "Invitation déjà utilisée";
    invitationCampaignErrorInvitationCompletedMsg: string = "Vous avez déjà réservé via cette invitation. Demandez une nouvelle invitation à l'entreprise pour réserver à nouveau.";
    invitationCampaignErrorInvitationExpiredTitle: string = "Invitation expirée";
    invitationCampaignErrorInvitationExpiredMsg: string = "Votre invitation pour planifier un rendez-vous avec cette entreprise a expiré";
    invoiceConnectingWithPaymentPortal: string = "Connexion au portail de paiement...";
    invoiceCreditCardChargeLabel: string = "Payer %INVOICE_PAYMENT_AMOUNT%";
    invoiceCreditCardChargeFailedLabel: string = "Nous n'avons pas pu traiter votre paiement pour le moment, veuillez réessayer. Contactez-nous si le problème persiste.";
    invoiceCreditCardChargingLabel: string = "Envoi du paiement...";
    invoiceCreditCardAuthorizeLabel: string = "Autoriser %INVOICE_PAYMENT_AMOUNT%";
    invoiceCreditCardAuthorizeFailedLabel: string = "Nous n'avons pas pu autoriser votre carte pour le moment. Veuillez réessayer. Contactez-nous si le problème persiste.";
    invoiceCreditCardAuthorizingLabel: string = "Autorisation de la carte...";
    invoiceDateIssuedLabel: string = "Date d'émission :";
    invoiceDetailTitle: string = "Détails de la facture";
    invoiceIdLabel: string = "ID de facture :";
    invoiceItemsTableHeader: string = "Données de la facture :";
    invoiceDownloadBtnLabel: string = "Télécharger";
    invoiceListErrorLoadingMsg: string = "Erreur de chargement des listes de factures. Réessayez plus tard";
    invoicePayBtnLabel: string = "Payer maintenant";
    invoiceQuoteDetailTitle: string = "Détails du devis";
    invoiceSubTotalLabel: string = "Sous-total :";
    invoiceEnterPaymentTitle: string = "Saisir les informations de la carte";
    invoiceTotalLabel: string = "Total de la facture :";
    invoicePaidToDateLabel: string = "Payé à ce jour :";
    invoiceBalanceLabel: string = "Solde :";
    invoiceStatusLabel: string = "Statut :";
    invoicesOpenListTitle: string = "Factures ouvertes";
    invoicesOpenBtnLabel: string = "Ouvertes";
    invoicesNoOpenMsg: string = "Aucune facture ouverte trouvée";
    invoicesClosedListTitle: string = "Factures fermées";
    invoicesClosedBtnLabel: string = "Fermée";
    invoicesNoClosedMsg: string = "Aucune facture fermée trouvée";
    invoicesVoidedListTitle: string = "Factures annulées";
    invoicesVoidedBtnLabel: string = "Annulée";
    invoicesNoVoidedMsg: string = "Aucune facture annulée trouvée";
    invoicesViewDetailBtnLabel: string = "<i class ='fas fa-search'> </i>";
    labelBack: string = "Retour";
    labelBookAnother: string = "Réserver un autre rendez-vous";
    labelNext: string = "Suivant";
    labelNoPreference: string = "* Aucune préférence *";
    labelSave: string = "Enregistrer";
    locationGroupTitle: string = "Groupes d'emplacements";
    locationGroupPnlErrorLoading: string = this.errorLoadingLabel;
    locationGroupPnlNoObjectsMsg: string = "Aucun groupe d'emplacements disponible";

    locationLabel: string = "Emplacement";
    locationsLabel: string = "Emplacements";
    loginErrorAccountLocked: string = "Votre compte a été verrouillé. Veuillez contacter le support.";
    loginErrorInvalidCredentials: string = "Identifiants incorrects. Veuillez réessayer.";
    loginErrorUnauthorized: string = "Non autorisé. Veuillez vérifier vos identifiants.";
    loginForgotPasswordLabel: string = "Mot de passe oublié ?";
    loginResetPasswordBtnLabel: string = "Réinitialiser le mot de passe";
    loginResetPasswordErrorMsg: string = "Erreur lors de la réinitialisation du mot de passe. Veuillez réessayer.";
    loginResetPasswordSuccessMsg: string = "Un lien de réinitialisation de mot de passe a été envoyé à votre adresse e-mail.";
    myAppointmentsListTitle: string = "Mes rendez-vous";
    myAppointmentsNoApptsMsg: string = "Aucun rendez-vous trouvé";
    noAvailableTimeSlotsMsg: string = "Aucun créneau horaire disponible";
    noLocationSelectedErrorMsg: string = "Aucun emplacement sélectionné. Veuillez choisir un emplacement.";
    noReasonSelectedErrorMsg: string = "Aucune raison sélectionnée. Veuillez choisir un service.";
    noStaffSelectedErrorMsg: string = "Aucun personnel sélectionné. Veuillez choisir un membre du personnel.";
    pendingApptTitle: string = "Rendez-vous en attente";
    previewApptBtnLabel: string = "Aperçu du rendez-vous";
    profileUpdateErrorMsg: string = "Erreur lors de la mise à jour du profil. Veuillez réessayer.";
    profileUpdateSuccessMsg: string = "Profil mis à jour avec succès";
    reasonLabel: string = "Motif";
    reasonsLabel: string = "Motifs";
    registerErrorAccountExists: string = "Un compte avec cet e-mail existe déjà";
    registerErrorGeneric: string = "Erreur lors de l'inscription. Veuillez réessayer.";
    rescheduleApptBtnLabel: string = "Reporter le rendez-vous";
    rescheduleApptTitle: string = "Reporter un rendez-vous";
    saveChangesErrorMsg: string = "Erreur lors de l'enregistrement des modifications";
    searchLabel: string = "Rechercher";
    selectLocationTitle: string = "Sélectionner un emplacement";
    selectReasonTitle: string = "Sélectionner un service";
    selectStaffTitle: string = "Sélectionner un membre du personnel";
    sendInvitationBtnLabel: string = "Envoyer une invitation";
    staffLabel: string = "Personnel";
    staffsLabel: string = "Personnel";
    timeLabel: string = "Heure";
    timesLabel: string = "Heures";
    titleLabel: string = "Titre";
    waitlistApptBtnLabel: string = "Liste d'attente";
    waitlistApptTitle: string = "Liste d'attente pour rendez-vous";
    waitlistErrorMsg: string = "Erreur lors de l'ajout à la liste d'attente";
    waitlistSuccessMsg: string = "Ajouté à la liste d'attente avec succès";

    constructor(private configListLabels: ConfigListMap,
                private titleCasePipe: TitleCasePipe,
                private cancelHrs: number,
                private rescheduleHrs: number,
                private waitListFirstAvailable: boolean) {}

    ngOnInit(): void {
        if(this.rescheduleHrs === 0){
            this.apptReschedulePastTimeframeMsg = "Le délai imparti pour reporter ce rendez-vous est dépassé. Le report est autorisé jusqu'à l'heure de début du rendez-vous.";
        }
        if(this.cancelHrs === 0){
            this.apptCancelPastTimeframeMsg = "Le délai accordé pour annuler ce rendez-vous est expiré. Les annulations sont autorisées jusqu'à l'heure de début du rendez-vous.";
        }

    }
}